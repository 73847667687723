import _ from 'lodash';
import { Button, List, Modal, Row, Col, Tag } from 'antd';

const ProcessInvoiceNotification = ({
  items,
  selectedRowKeys,
  handleInvoiceNotificationModal,
  openInvoiceNotificationModal,
  cancelProcessModal,
  invoiceNotificationLoading,
  processInvoiceNotification,
  tagColor
}) => {
  return (
    <>
      <Button
        disabled={!selectedRowKeys.length}
        type="primary"
        size="large"
        onClick={handleInvoiceNotificationModal}>
        Notificar Facturacion
      </Button>
      <Modal
        title="Notificar Facturacion"
        footer={false}
        width={800}
        onCancel={cancelProcessModal}
        visible={openInvoiceNotificationModal}>
        <p>
          Notificarás la facturacion a las siguientes {selectedRowKeys.length} empresas:
      </p>

        <List>
          {items && items.length && selectedRowKeys.map(key => {
            const item = _.find(items, { id: key });
            return (
              <List.Item>
                <span style={{ display: 'inline-block', width: 400 }}>{item.company_name}</span>
                <Tag color={tagColor(item.agreement_type)}>{item.agreement_type}</Tag>
              </List.Item>
            )
          })}
        </List>

        <Row justify="end" className="mt-40">
          <Col>
            <Button
              loading={invoiceNotificationLoading}
              onClick={processInvoiceNotification}
              size="large"
              type="primary">
              Notificar
          </Button>
          </Col>
        </Row>
      </Modal>
    </>
  )
}

export default ProcessInvoiceNotification;