import { Modal, Button, Input, Row, Col, List, message } from 'antd';
import React, { useState } from 'react';
import moment from 'moment';
import invoiceService from '../../services/invoiceService';
import { formatAmountByCurrency } from '../../helpers';

const { Search } = Input;

const AssociateInvoice = ({ month, year, billing, setId, actionSize, oc }) => {
  const date = moment().set({ month, year });
  const [showAassociateInvoiceModal, setShowAassociateInvoiceModal] = useState(false);
  const [invoice, setInvoice] = useState();
  const [loading, setLoading] = useState(false);

  const handleShowAssociateInvoiceModal = () => {
    setShowAassociateInvoiceModal(true);
  }

  const handleCancel = () => {
    setInvoice();
    setShowAassociateInvoiceModal(false);
  };

  const onSearch = (value) => {
    setLoading(true)
    const data = { number: value, countryId: billing.currency.country_id };
    invoiceService
      .fetchExternalInvoice(data)
      .then((response) => setInvoice(response))
      .finally(() => setLoading(false));
  }

  const associateInvoice = () => {
    setLoading(true);
    const data = { number: invoice.number, billingId: billing.id, countryId: billing.currency.country_id };

    if (oc) {
      data['oc'] = oc;
    }

    invoiceService.
      associateInvoice(data)
      .then(() => {
        message.success('Factura asociada');
      })
      .finally(() => {
        setLoading(false);
        setShowAassociateInvoiceModal(false);
        setId(moment.unix());
      });
  }

  return (
    <React.Fragment>
      <Button
        onClick={handleShowAssociateInvoiceModal}
        type="outline"
        size={actionSize}
        className="mr-20"
      >
        Asociar Factura
      </Button>

      <Modal
        onCancel={handleCancel}
        footer={false}
        title={<>
          <div>Asociar factura externa a {date.format('LL')}</div>
          {
            oc && <span style={{ fontSize: 12 }}>{`Orden de Compra ${oc}`}</span>
          }
        </>}
        visible={showAassociateInvoiceModal}
      >
        {
          !invoice ?
            <Search
              loading={loading}
              placeholder="N de Factura"
              allowClear
              enterButton="Buscar"
              size="large"
              onSearch={onSearch}
            /> : (
              <Row>
                <Col lg={24}>
                  <List>
                    <List.Item>
                      Fecha de emisión: <strong>{invoice.emissionDate}</strong>
                    </List.Item>
                    <List.Item>
                      Monto: <strong>
                        {formatAmountByCurrency({
                          countryCode: billing.currency.locate_code,
                          currencyCode: billing.currency.code,
                          amount: invoice.totalAmount
                        })}
                      </strong>
                    </List.Item>
                    <List.Item>
                      Empresa: <strong>{invoice.client.company}</strong>
                    </List.Item>
                  </List>
                </Col>
                <Col lg={24}>
                  <div style={{ float: 'right' }}>
                    <Button
                      loading={loading}
                      size="large"
                      type="primary"
                      onClick={associateInvoice}
                    >
                      Asociar
                    </Button>
                  </div>
                </Col>
              </Row>
            )
        }
      </Modal>
    </React.Fragment>
  )
}

export default AssociateInvoice;

