import React, { Fragment, useState, useEffect } from "react";
import {
  Table,
  Tag,
  Checkbox,
  Row,
  Col,
  Button,
  Tooltip,
  Popconfirm
} from "antd";
import _ from "lodash";
import moment from "moment";
import billingInvoice from '../../services/billingService';

const WithObservationsTable = ({
  withObservations,
  loading,
  rejectReasonOptions,
  setId,
  billing
}) => {
  const [selected, setSelected] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const showPopconfirm = () => {
    setVisible(true);
  };

  const handleOk = async () => {
    setConfirmLoading(true);
    const ids = selectedRowKeys;
    const billingId = billing.id;
    await billingInvoice.fixObservation({ ids, billingId });
    setId(moment().unix());
    setVisible(false);
    setConfirmLoading(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const columns = [
    {
      title: 'Reserva y Sucursal',
      dataIndex: 'number',
      key: 'number',
      render: (text, record) => {
        return (
          <Fragment>
            <div className="text-bold">{text}</div>
            <div>
              <Tag>{record.branch_name}</Tag>
            </div>
          </Fragment>
        )
      }
    },
    {
      title: 'Fecha',
      dataIndex: 'admission_date',
      key: 'admission_date',
      render: (text) => {
        return moment(text, 'YYYY-MM-DD_HH-mm-ssZ').format('YYYY-MM-DD')
      }
    },
    {
      title: 'Paciente',
      dataIndex: 'patient_name',
      key: 'patient_name',
      render: (text, record) => {
        return (
          <Fragment>
            <div>{text}</div>
            <div>{record.patient_document}</div>
          </Fragment>
        )
      }
    },
    {
      title: 'Prestación',
      dataIndex: 'prestation_name',
      key: 'prestation_name',
      render: (text, record) => {
        return (
          <Fragment>
            <div>{text}</div>
            {
              record.observation_reason && <div>
                {
                  <Tooltip placement="topLeft" title={record.observation_message}>
                    <Tag color="orange">
                      {_.find(rejectReasonOptions, {
                        value: record.observation_reason
                      }).label}
                    </Tag>
                  </Tooltip>
                }
              </div>
            }
          </Fragment>
        )
      }
    },
    {
      width: 300,
      title: 'Solicitante',
      dataIndex: 'user_name',
      key: 'user_name',
      render: (text, record) => {
        return (
          <React.Fragment>
            <div>{text}</div>
            {
              record.cost_center_name && (
                <div>
                  {`C.C `}
                  <Tag>
                    {record.cost_center_name}
                  </Tag>
                </div>
              )
            }
          </React.Fragment>
        )
      }
    },
    {
      title: 'Monto',
      dataIndex: 'amount',
      key: 'amount',
      render: (text, record) => {
        return (
          <div className="text-bold">
            {
              record.discount_amount > 0 && <div style={{ fontSize: 10, textAlign: 'right', color: 'grey' }}>
                {new Intl.NumberFormat("es-CL").format(record.sub_amount)}
              </div>
            }
            <div style={{ textAlign: 'right' }}>
              ${new Intl.NumberFormat("es-CL").format(text)}
            </div>
          </div>
        );
      }
    },
    {
      title: () => {
        return (
          <Checkbox
            checked={checkAll}
            onChange={(event) => {
              if (event.target.checked) {
                setSelectedRowKeys(_.map(selected, 'id'));
              } else {
                setSelectedRowKeys([]);
              }
              setCheckAll(event.target.checked);
            }}
          />
        )
      },
      dataIndex: 'check',
      key: 'check',
      render: (text, record) => {
        return (
          <Checkbox
            checked={checkOn(record.id)}
            onChange={(event) => {
              checkOnChange(event.target.checked, record)
            }}
          />
        )
      }
    },
  ]

  const checkOnChange = (checked, record) => {
    if (checked) {
      setSelectedRowKeys([...selectedRowKeys, record.id])
    } else {
      setSelectedRowKeys(selectedRowKeys.filter(key => {
        return key !== record.id;
      }));
    }
  }

  const checkOn = (id) => {
    return selectedRowKeys.includes(id);
  }

  useEffect(() => {
    setSelected(withObservations);
  }, [])

  return (
    <Fragment>
      <Row>
        <Col lg={24}>
          <Table dataSource={withObservations}
            columns={columns}
            loading={loading}
            pagination={false}
            className="prestation-table"
          />
        </Col>
      </Row>
      <Row justify="end" gutter={10}>
        <Col>
          <Popconfirm
            title="Confirmación"
            visible={visible}
            onConfirm={handleOk}
            okButtonProps={{ loading: confirmLoading }}
            onCancel={handleCancel}
          >
            <Button
              disabled={selectedRowKeys.length == 0}
              onClick={showPopconfirm}
              type="primary"
              size="large">
              Marcar como corregido
            </Button>
          </Popconfirm>
        </Col>
      </Row>
    </Fragment>
  )
}

export default WithObservationsTable