import React from 'react';
import { render } from 'react-dom';
import { App } from './App';
import 'antd/dist/antd.css';
import './index.less';


render(
  <App />,
  document.getElementById('root')
);