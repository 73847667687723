import React from 'react';
import { Row, Col, Spin } from "antd";
import { formatAmountByCurrency } from "../../helpers";

const DetailCard = ({ text, data, loading, state }) => {
  return (
    <Spin spinning={loading}>
      <div className={`detail-card ${state}`}>
        <Row>
          <Col lg={24} className="quantity">
            {data ? data.count : 0}
          </Col>
          <Col lg={24} className="state">
            {text}
          </Col>
          <Col lg={24} className="amount">
            Monto: {data ? formatAmountByCurrency({
              countryCode: data.currency.locate_code,
              currencyCode: data.currency.code,
              amount: data.sum
            }) : 0}
          </Col>
        </Row>
      </div>
    </Spin>
  )
}

export default DetailCard;