import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';

import {
  Table,
  Button,
  Tag,
  Checkbox,
  Row,
  Col,
} from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import billingInvoice from '../../services/billingService';
import processesService from '../../services/processesService';
import ProcessNotProcessed from './ProcessNotProcessed';
import ProcessInvoiceNotification from './ProcessInvoiceNotification';
import { formatAmountByCurrency } from '../../helpers';

const tagColor = (text) => {
  if (text === 'Orden de Compra') {
    return '#E09396'
  }

  if (text === 'Factura') {
    return '#80B8DE'
  }

  return '#8D7299';
}

const AgreementList = ({ date, state, setId, id }) => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [processingLoading, setProcessingLoading] = useState(false);
  const [invoiceNotificationLoading, setInvoiceNotificationLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [openProcessModal, setOpenProcessModal] = useState();
  const [openInvoiceNotificationModal, setOpenInvoiceNotificationModal] = useState();

  const checkOnChange = (checked, record) => {
    if (checked) {
      setSelectedRowKeys([...selectedRowKeys, record.id])
    } else {
      setSelectedRowKeys(selectedRowKeys.filter(key => {
        return key !== record.id;
      }));
    }
  }

  const checkOn = (id) => {
    return selectedRowKeys.includes(id);
  }

  const canProcess = (record) => {
    return (
      (state === 'not_processing' && record.agreement_type !== 'Factura')
      || (state === 'invoiced' && record.state === 'invoiced')
    );
  }

  const columns = [
    {
      title: 'Empresa',
      dataIndex: 'company_name',
      key: 'company_name',
      render: (text, record) => {
        return (
          <React.Fragment>
            {text}
            {!record.active && <Tag className="ml-5" color="red">Convenio inactivo</Tag>}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Tipo de Convenio',
      dataIndex: 'agreement_type',
      key: 'agreement_type',
      render: (text, record) => {
        return (
          <React.Fragment>
            <Tag color={tagColor(text)}>
              {text}
            </Tag>
            {
              record.state === 'invoiced_notified' && <CheckOutlined style={{ color: '#52c41a' }} />
            }
          </React.Fragment>
        )
      }
    },
    {
      title: 'Prefactura',
      dataIndex: 'preinvoice',
      key: 'preinvoice',
      render: (_, record) => {
        let url = `/consolidator/${date.year()}/${date.month() + 1}`;
        url += `/prestations/${record.company_id}`;
        return (
          <Link to={url}>
            <Button type="primary">Ver</Button>
          </Link>
        )
      }
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      render: (text, record) => {
        return (
          <React.Fragment>
            <strong>
              {formatAmountByCurrency({
                countryCode: record.currency.locate_code,
                currencyCode: record.currency.code,
                amount: text
              })}
            </strong>

            {
              record.invoice_total && <div style={{ fontSize: 12 }}>
                facturado {formatAmountByCurrency({
                  countryCode: record.currency.locate_code,
                  currencyCode: record.currency.code,
                  amount: record.invoice_total
                })}
              </div>
            }
          </React.Fragment>
        );
      }
    },
    {
      width: 20,
      title: () => {
        return (
          <Checkbox
            checked={checkAll}
            onChange={(event) => {
              if (event.target.checked) {
                if (state === 'not_processing') {
                  setSelectedRowKeys(_.map(_.filter(items, (item) => {
                    return item.agreement_type !== 'Factura'
                  }), 'id'));
                } else {
                  setSelectedRowKeys(_.map(items, 'id'));
                }
              } else {
                setSelectedRowKeys([]);
              }
              setCheckAll(event.target.checked);
            }}
          />
        )
      },
      dataIndex: 'check',
      key: 'check',
      render: (text, record) => {
        return (
          canProcess(record) && (
            <Checkbox
              checked={checkOn(record.id)}
              onChange={(event) => {
                checkOnChange(event.target.checked, record)
              }}
            />
          )
        )
      }
    },
  ];

  const fetchItems = async () => {
    setLoading(true);
    const response = await billingInvoice.fetchInvoices({
      type: state,
      month: date.month() + 1,
      year: date.year()
    });

    setLoading(false);
    setItems(response);
  }

  useEffect(() => {
    fetchItems();
  }, [date, id]);

  const handleProcessModal = (event) => {
    setOpenProcessModal(true);
  }

  const handleInvoiceNotificationModal = (event) => {
    setOpenInvoiceNotificationModal(true);
  }

  const processNotProcessed = async () => {
    setProcessingLoading(true);
    const ids = selectedRowKeys;
    await processesService.processNotProcessed({
      ids,
      month: date.month() + 1,
      year: date.year()
    });

    setSelectedRowKeys([]);
    setItems([]);
    setProcessingLoading(false);
    setOpenProcessModal(false);
    setId(moment().unix().toString());
  }

  const processInvoiceNotification = async () => {
    setInvoiceNotificationLoading(true);
    const ids = selectedRowKeys;
    await processesService.processInvoiceNotification({
      ids,
      month: date.month() + 1,
      year: date.year()
    });

    setSelectedRowKeys([]);
    setItems([]);
    setInvoiceNotificationLoading(false);
    setOpenInvoiceNotificationModal(false);
    setId(moment().unix().toString());
  }

  const cancelProcessModal = (event) => {
    setOpenProcessModal(false);
  }

  return (
    <Row>
      <Col lg={24} className="mt-20 mb-20">
        <div style={{ float: 'right' }}>
          {state === 'not_processing' && (
            <ProcessNotProcessed
              selectedRowKeys={selectedRowKeys}
              handleProcessModal={handleProcessModal}
              cancelProcessModal={cancelProcessModal}
              openProcessModal={openProcessModal}
              processingLoading={processingLoading}
              processNotProcessed={processNotProcessed}
              items={items}
              tagColor={tagColor}
            />
          )}
        </div>
        <div style={{ float: 'right' }}>
          {state === 'invoiced' && (
            <ProcessInvoiceNotification
              selectedRowKeys={selectedRowKeys}
              handleInvoiceNotificationModal={handleInvoiceNotificationModal}
              cancelProcessModal={cancelProcessModal}
              openInvoiceNotificationModal={openInvoiceNotificationModal}
              invoiceNotificationLoading={invoiceNotificationLoading}
              processInvoiceNotification={processInvoiceNotification}
              items={items}
              tagColor={tagColor}
            />
          )}
        </div>
      </Col>
      <Col lg={24}>
        <Table dataSource={items}
          columns={columns}
          pagination={false}
          loading={loading}
        />
      </Col>
    </Row>
  )
}

export default AgreementList;