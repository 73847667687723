import React, { useEffect, useState } from 'react';
import { Form, Input, Row, Col, Select, DatePicker, Button, Table, message, Checkbox } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import _ from 'lodash';
import Detail from './Detail';
import invoiceService from '../../../services/invoiceService';
import moment from 'moment-timezone';

const Option = Select;

const Documents = ({
   companyId,
   billingId,
   countryId,
   customComment,
   specialComment,
   quantity,
   type,
   reservationId
}) => {
   const [referenceDocumentTypes, setReferenceDocumentTypes] = useState();
   const [referenceDocuments, setReferenceDocuments] = useState([]);
   const [isConfirmation, setIsConfirmation] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
   const [payments, setPayments] = useState([]);
   const [attributes, setAttributes] = useState([]);
   const [paymentsIsValid, setPaymentIsValid] = useState(true);
   const [invoiceDate, setInvoiceDate] = useState(false);
   const [comment, setComment] = useState();
   const [isForeigner, setIsForeigner] = useState(false);

   const location = useLocation();
   const history = useHistory();
   const [form] = Form.useForm();

   const fetchReferenceDocumentTypes = async () => {
      const response = await invoiceService.fetchReferenceDocuments({ countryId });
      setReferenceDocumentTypes(response);
   }

   useEffect(() => {
      setComment(customComment)
   }, [customComment])

   const columns = [
      {
         title: 'Folio',
         dataIndex: 'number',
         key: 'number',
      },
      {
         title: 'Fecha',
         dataIndex: 'referenceDate',
         key: 'referenceDate',
         render: (text, record) => {
            return moment.unix(text).format('YYYY-MM-DD')
         }
      },
      {
         title: 'Tipo de Referencia',
         dataIndex: 'codeSii',
         key: 'codeSii',
         render: (text, record) => {
            return referenceDocumentTypes.find(item => item.codeSii == record.codeSii).name;
         }
      },
      {
         title: 'Razon de Referencia',
         dataIndex: 'reason',
         key: 'reason',

      },
      {
         title: '-',
         dataIndex: '',
         key: '',
         render: (text, record) =>
            <Button title="Sure to delete?" onClick={() => {
               setReferenceDocuments(_.filter(referenceDocuments, (item) => {
                  return item.number != record.number;
               }));
            }}>
               Quitar
            </Button>
      },
   ]

   useEffect(() => {
      fetchReferenceDocumentTypes();
   }, [location]);

   const submit = (values) => {
      form.setFieldsValue({
         number: '',
         referenceDate: '',
         codeSii: '',
         reason: ''
      });
      const referenceDate = values['referenceDate'].format('YYYY-MM-DD');
      values['referenceDate'] = moment(referenceDate, 'YYYY-MM-DD').tz('America/Santiago').unix();
      setReferenceDocuments(referenceDocuments => [...referenceDocuments, values]);
   }

   const getOC = () => {
      const query = new URLSearchParams(location.search);
      return query.get('oc');
   }

   const handleInvoiceDate = (date) => {
      setInvoiceDate(date.format('YYYY-MM-DD'));
   }

   const createInvoice = async () => {

      if (type != 'agreement' && !payments.length) {
         message.error('Debes agregar al menos un método de pago');
         return
      }

      if (type != 'agreement' && !paymentsIsValid) {
         message.error('Los métodos de pagos no cuadran con el monto total');
         return
      }

      setIsLoading(true);
      const oc = getOC();
      let params;

      if (type === 'agreement') {
         params = {
            countryId,
            companyId,
            billingId,
            invoiceDate,
            referenceDocuments,
            type,
            isForeigner
         };

         if (oc) {
            params['oc'] = oc;
         }
      } else if (type === 'reservation') {
         params = {
            countryId,
            companyId,
            reservationId,
            invoiceDate,
            referenceDocuments,
            type,
            payments,
            attributes,
            isForeigner
         };
      } else {
         params = {
            countryId,
            reservationId,
            invoiceDate,
            referenceDocuments,
            quantity,
            specialComment,
            type,
            payments,
            attributes,
            isForeigner
         };
      }

      if (comment) {
         params['customMessage'] = comment;
      }

      try {
         const response = await invoiceService.createInvoice(params);
         message.success({
            content: `Facturado Exitosamente, Nº de Factura ${response.number}`,
            style: {
               marginTop: '40vh',
            },
         });

         if (type === 'agreement') {
            history.push(`/consolidator/${response['year']}/${response['month']}/prestations/${companyId}`);
         } else if (type === 'reservation') {
            history.push(`/general`);
         }
      } catch (error) {
         message.error({
            content: error.toString(),
            style: {
               marginTop: '40vh',
            },
         });
      } finally {
         setIsLoading(false);
      }

   };

   const goToConfirmation = () => {
      if (!invoiceDate) {
         message.error('Debes ingresar una fecha de facturacion');
         return;
      }

      setIsConfirmation(true)
   }

   const handleForeign = (event) => {
      setIsForeigner(event.target.checked);
   }

   return (
      <React.Fragment>
         {
            isConfirmation && (
               <Row justify="end">
                  <Col>
                     <Checkbox onChange={handleForeign}>
                        Es extranjero?
                     </Checkbox>
                  </Col>
               </Row>
            )
         }
         <Row>
            <Col lg={24}>
               <Row className="mb-40">
                  <Col>
                     <h2>Fecha de facturacion</h2>
                     {!isConfirmation ? (
                        <DatePicker style={{ width: '100%' }} placeholder="Fecha" onChange={handleInvoiceDate} />
                     ) : <div>{invoiceDate}</div>}
                  </Col>
               </Row>
               {!isConfirmation ? <Form layout="vertical" onFinish={submit} form={form}>
                  <h2>Documentos de Referencia</h2>
                  <Row gutter={20}>
                     <Col lg={4}>
                        <Form.Item name="number" label="Folio" >
                           <Input />
                        </Form.Item>
                     </Col>
                     <Col lg={4}>
                        <Form.Item name="referenceDate" label="Fecha">
                           <DatePicker style={{ width: '100%' }} placeholder="Fecha" />
                        </Form.Item>
                     </Col>
                     <Col lg={5}>
                        <Form.Item name="codeSii" label="Tipo documento">
                           <Select>
                              {referenceDocumentTypes && referenceDocumentTypes.map(document => {
                                 return (
                                    <Option value={document.codeSii} key={document.codeSii}>
                                       {document.name}
                                    </Option>
                                 )
                              })}
                           </Select>
                        </Form.Item>
                     </Col>
                     <Col lg={4}>
                        <Form.Item name="reason" label="Razon Referencia">
                           <Input />
                        </Form.Item>
                     </Col>
                     <Col lg={4}>
                        <Form.Item label=" ">
                           <Button type="primary" htmlType="submit">Agregar</Button>
                        </Form.Item>
                     </Col>
                  </Row>
               </Form> : (
                  <div className="mb-40">
                     <Detail
                        type={type}
                        customComment={customComment}
                        billingId={billingId}
                        countryId={countryId}
                        companyId={companyId}
                        reservationId={reservationId}
                        isConfirmation={isConfirmation}
                        payments={payments}
                        setPayments={setPayments}
                        setPaymentIsValid={setPaymentIsValid}
                        setAttributes={setAttributes}
                     />
                  </div>
               )}
            </Col>
            <Col lg={24}>
               {isConfirmation && <h2>Documentos de Referencia</h2>}
               <Table
                  dataSource={referenceDocuments}
                  columns={columns}
                  pagination={false}
               />
            </Col>
         </Row>
         <Row justify="end">
            {
               !isConfirmation ? <Col>
                  <Button
                     type="primary"
                     size="large"
                     onClick={goToConfirmation}
                  >
                     Siguiente
                  </Button>
               </Col> : <Col>
                  <Button
                     loading={isLoading}
                     type="primary"
                     size="large"
                     onClick={createInvoice}
                  >
                     Facturar
                  </Button>
               </Col>
            }
         </Row>
      </React.Fragment>
   );
};

export default Documents;