import { authHeader, handleResponse } from "./helper";
import config from '../config';

const ENDPOINT_URL = 'https://run.mocky.io/v3';

const unBillReservations = async (params) => {
   const url = new URL(`${config.apiUrl}/reservations/unBilledReservations`);
   Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
   const response = await fetch(url, {
      method: 'GET',
      headers: authHeader()
   });
   const dataResponse = response.json();
   return dataResponse;
};

const servicesByReservation = async (params) => {
   const url = new URL(`${config.apiUrl}/reservations/servicesByReservation`);
   Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
   const response = await fetch(url, {
      method: 'GET',
      headers: authHeader()
   });
   const dataResponse = response.json();
   return dataResponse;
};

const invoicesByReservation = async (params) => {
   const url = new URL(`${config.apiUrl}/reservations/invoicesByReservation`);
   Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
   const response = await fetch(url, {
      method: 'GET',
      headers: authHeader()
   });
   const dataResponse = response.json();
   return dataResponse;
};

const associateInvoiceToReservation = async (params) => {
   const url = `${config.apiUrl}/reservations/associateInvoice`;
   const response = await fetch(url, {
      method: 'POST',
      headers: {
         ...authHeader(),
         'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
   });
   const dataResponse = response.json();
   return dataResponse;
};

const disassociateInvoiceToReservation = async (params) => {
   const url = `${config.apiUrl}/reservations/disassociateInvoice`;
   const response = await fetch(url, {
      method: 'POST',
      headers: {
         ...authHeader(),
         'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
   });
   const dataResponse = response.json();
   return dataResponse;
}

const billedReservations = async (params) => {
   const url = new URL(`${config.apiUrl}/reservations/billedReservations`);
   Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
   const response = await fetch(url, {
      method: 'GET',
      headers: authHeader()
   });
   const dataResponse = response.json();
   return dataResponse;
};

const paymentMethods = async () => {
   const url = `${config.apiUrl}/reservations/paymentMethods`;
   const response = await fetch(url, {
      method: 'GET',
      headers: authHeader()
   });
   const dataResponse = response.json();
   return dataResponse;
};

const reservationsToBillWithItems = async (params) => {
   const url = new URL(`${config.apiUrl}/reservations/billedReservationsWithPendingItems`);
   Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
   const response = await fetch(url, {
      method: 'GET',
      headers: authHeader()
   });
   const dataResponse = response.json();
   return dataResponse;
};

const getExternalInvoice = async (params) => {
   const url = new URL(`${config.apiUrl}/reservations/getExternalInvoice`);
   Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
   const response = await fetch(url, {
      method: 'GET',
      headers: authHeader()
   });
   const dataResponse = response.json();
   return dataResponse;
}

const branchOfficesByAdmin = async (params) => {
   const url = new URL(`${config.apiUrl}/reservations/branchOfficesByAdmin`);
   const response = await fetch(url, {
      method: 'GET',
      headers: authHeader()
   });
   const dataResponse = response.json();
   return dataResponse;
}

export const generalInvoiceService = {
   branchOfficesByAdmin,
   unBillReservations,
   disassociateInvoiceToReservation,
   associateInvoiceToReservation,
   billedReservations,
   paymentMethods,
   reservationsToBillWithItems,
   servicesByReservation,
   invoicesByReservation,
   getExternalInvoice
};