import React from 'react';
import { Route } from 'react-router-dom';
import Cookies from 'universal-cookie';
import config from '../config';
import PrivateLayout from './PrivateLayout';
import Forbidden from './Forbidden';

function PrivateRoute({ component: Component, roles, ...rest }) {
  const cookies = new Cookies();;
  const subdomain = window.location.host.split('.')[0];
  return (
    <Route {...rest} render={props => {

      if (subdomain.includes('facturacion')) {
        if (!cookies.get('user') || !cookies.get('roles')) {
          window.location.href = config.loginRedirect;
          return;
        }

        return <PrivateLayout component={Component} {...rest} />
      }

      return <Forbidden />
    }} />
  );
}

export { PrivateRoute };