import React, { useEffect, useState } from "react";
import { Row, Col, Form, Select, Input, Button, Table, InputNumber } from 'antd';
import invoiceService from '../../../services/invoiceService';
import { formatAmountByCurrency } from "../../../helpers";
import * as _ from 'lodash';

const Payments = ({
  countryId,
  isConfirmation,
  type,
  payments,
  setPayments,
  setPaymentIsValid,
  prestations,
  setAttributes
}) => {
  const [paymentMethods, setPaymentMethods] = useState();
  const [dynamicAttributes, setDynamicAttributes] = useState([]);
  const [paymentform] = Form.useForm()
  const [prestation] = useState(prestations[0])

  const addPayments = (values) => {
    const _dynamicAttributes = [];
    let items = payments;

    items.push({
      payment: values['payment'],
      amount: parseFloat(values['amount'])
    });

    for (let key of Object.keys(values)) {
      if (key.includes("dynamic-attribute")) {
        _dynamicAttributes.push({
          description: `${values[key]}`,
          dynamicAttributeId: parseInt(key.split('dynamic-attribute-')[1])
        })
      }
    }

    setAttributes(_dynamicAttributes);
    setPayments([...items]);
    paymentform.resetFields();
    paymentsTotalValidation(items);
  }

  const getPaymentName = (value) => {
    const payment = paymentMethods.find((item) => item.id == value);
    return payment['name'];
  }

  const fetchPaymentMethods = async () => {
    const response = await invoiceService.fetchExternalPaymentMethods({ countryId });
    setPaymentMethods(response);
  };

  const paymentsTotalValidation = async (items) => {
    const sum = _.sumBy(items, 'amount');
    setPaymentIsValid(sum == prestation['total']);
  }

  const handlePayments = async (paymentId) => {
    const response = await invoiceService.fetchExternalDynamicAttributes({ countryId, paymentId });
    setDynamicAttributes(response);
  }

  useEffect(() => {
    fetchPaymentMethods();
  }, [])

  return (
    <React.Fragment>
      {
        type !== "agreement" && isConfirmation && (
          <Col lg={24}>
            <Form form={paymentform} onFinish={addPayments} layout="vertical">
              <h2>Método de pago</h2>

              <Row gutter={10}>
                <Col lg={6}>
                  <Form.Item
                    name="payment"
                    rules={[{ required: true, message: 'Este campo es requerido' }]}
                  >
                    <Select placeholder="Método de pago" onChange={handlePayments}>
                      {
                        paymentMethods && paymentMethods.map(payment => {
                          return <Select.Option value={payment.id}>{payment.name}</Select.Option>
                        })
                      }
                    </Select>
                  </Form.Item>
                </Col>
                <Col lg={6}>
                  <Form.Item
                    name="amount"
                    rules={[{ required: true, message: 'Este campo es requerido' }]}
                  >
                    <InputNumber
                      placeholder="Importe"
                      style={{ width: 100 }}
                      min={1}
                    />
                  </Form.Item>
                </Col>
                <Col lg={6}>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">Agregar</Button>
                  </Form.Item>
                </Col>
              </Row>

              {
                dynamicAttributes.length > 0 && (
                  (
                    <Row gutter={10}>
                      {dynamicAttributes.map((dynamicAttribute, index) => {
                        return (
                          <Col lg={6}>
                            {
                              dynamicAttribute.type === 4 && (
                                <Form.Item name={`dynamic-attribute-${dynamicAttribute.id}`}>
                                  <Input placeholder={dynamicAttribute.name} />
                                </Form.Item>
                              )
                            }

                            {
                              dynamicAttribute.type === 7 && (
                                <Form.Item name={`dynamic-attribute-${dynamicAttribute.id}`}>
                                  <Select placeholder={dynamicAttribute.name}>
                                    {
                                      dynamicAttribute.details.items.map(detail => {
                                        return (
                                          <Select.Option value={detail.id}>{detail.name}</Select.Option>
                                        )
                                      })
                                    }
                                  </Select>
                                </Form.Item>
                              )
                            }
                          </Col>
                        )
                      })}

                    </Row>
                  )
                )
              }
            </Form>

            <Table
              dataSource={payments}
              columns={[
                {
                  title: 'Monto',
                  dataIndex: 'amount',
                  key: 'amount',
                  render: (text, record) => {
                    return (
                      <span>
                        {formatAmountByCurrency({
                          countryCode: prestation.currency.locate_code,
                          currencyCode: prestation.currency.code,
                          amount: text
                        })}
                      </span>
                    )
                  }

                },
                {
                  title: 'Metodo de pago',
                  dataIndex: 'payment',
                  key: 'payment',
                  render: (text, record) => {
                    return <span>
                      {getPaymentName(text)}
                    </span>
                  }
                },
                {
                  title: '-',
                  dataIndex: '',
                  key: '',
                  render: (text, record) =>
                    <Button title="Sure to delete?" onClick={() => {
                      setPayments(_.filter(payments, (item) => {
                        return item.payment != record.payment;
                      }));
                    }}>
                      Quitar
                    </Button>
                },
              ]}
              pagination={false}
            />
          </Col>
        )
      }
    </React.Fragment>
  )
}

export default Payments;