import { Popover, Row, Col } from "antd";
const { format } = require('rut.js');

const CompanyDetail = ({ record, text }) => {
  return (
    <Popover
      placement="topLeft"
      trigger="click"
      content={
        <Row justify="center" className="pt-10 pb-10 pl-10 pr-10">
          <Col lg={24} xs={24}>
            <h3>{record.company.business_name}</h3>
          </Col>
          <Col lg={24} xs={24}>
            <Row>
              <Col className="mr-10">RUT:</Col>
              <Col>{format(record.company.rut)}</Col>
            </Row>
            <Row>
              <Col className="mr-10">Giro:</Col>
              <Col>{record.company.activity}</Col>
            </Row>
            <Row>
              <Col className="mr-10">Correo electrónico:</Col>
              <Col>{record.company.email}</Col>
            </Row>
            <Row>
              <Col className="mr-10">Teléfono:</Col>
              <Col>{record.company.phone}</Col>
            </Row>
          </Col>
        </Row>
      }
    >
      <p className="mb-0" style={{ cursor: 'pointer' }}>{text}</p>
    </Popover>
  )
}

export default CompanyDetail;