import React from 'react';
import { Select, Row, Col } from 'antd';
import _ from 'lodash';

const { Option } = Select;

const SelectFilter = ({ name, type, setFilter, items }) => {
  return (
    <Select
      mode="multiple"
      showSearch
      allowClear={true}
      className="full-width"
      placeholder={name}
      optionFilterProp="children"
      onChange={(value) => {
        setFilter(type, value);
      }}
      onClear={() => {
        setFilter(type, null);
      }}
      filterOption={(input, option) => {
        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }}
    >
      {
        items.map(item => {
          return (
            <Option key={item[type]} value={item[type]}>
              {item[type]}
            </Option>
          )
        })
      }
    </Select>
  )
}

const Filters = ({ prestations, setFilter }) => {
  return (
    <React.Fragment>
      <Row gutter={10} className="mb-40">
        <Col lg={4}>
          <SelectFilter
            type="number"
            name="Reserva"
            items={_.uniqBy(prestations, 'number')}
            setFilter={setFilter}
          />

        </Col>
        <Col lg={4}>
          <SelectFilter
            type="branch_name"
            name="Sucursal"
            items={_.uniqBy(prestations, 'branch_name')}
            setFilter={setFilter}
          />
        </Col>
        <Col lg={4}>
          <SelectFilter
            type="prestation_name"
            name="Prestación"
            items={_.uniqBy(prestations, 'prestation_name')}
            setFilter={setFilter}
          />
        </Col>
        <Col lg={4}>
          <SelectFilter
            type="user_name"
            name="Solicitante"
            items={_.uniqBy(prestations, 'user_name')}
            setFilter={setFilter}
          />
        </Col>
        <Col lg={4}>
          <SelectFilter
            type="cost_center_name"
            name="Centro de Costo"
            items={_.uniqBy(prestations, 'cost_center_name')}
            setFilter={setFilter}
          />
        </Col>
      </Row>
    </React.Fragment >
  )
}

export default Filters;