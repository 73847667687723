import { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import moment from 'moment';

const HomePage = () => {
const date = moment();

return (
      <Redirect to={`/consolidator/${date.year()}/${date.month() + 1}`} />
   )
}

export default HomePage;