import React from 'react';
import { Route } from 'react-router-dom';
import PublicLayout from './PublicLayout';

function PublicRoute({ component: Component, roles, ...rest }) {

  return (
    <Route {...rest} render={props => {
      return <PublicLayout component={Component} {...rest} />
    }} />
  );
}

export { PublicRoute };