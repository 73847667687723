import { authHeader, countryHeader, handleResponse } from './helper';
import config from '../config';

const fetchInvoices = (params) => {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };
  const url = new URL(`${config.apiUrl}/billings`);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  return fetch(url, requestOptions).then(handleResponse);
}

const fetchBillingById = (params) => {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };
  const url = new URL(`${config.apiUrl}/billings/byId`);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  return fetch(url, requestOptions).then(handleResponse);
}

const fetchSummary = (params) => {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };
  const url = new URL(`${config.apiUrl}/billings/summary`);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  return fetch(url, requestOptions).then(handleResponse);
}

const fetchCheck = (params) => {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };
  const url = new URL(`${config.apiUrl}/billings/check`);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  return fetch(url, requestOptions).then(handleResponse);
}

const openMonth = (params) => {
  const requestOptions = {
    method: 'POST',
    headers: authHeader()
  };
  const url = new URL(`${config.apiUrl}/conciliator/openMonth`);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  return fetch(url, requestOptions).then(handleResponse);
}

const fetchPrestations = (params) => {
  const requestOptions = {
    method: 'GET',
    headers: countryHeader()
  };
  const url = new URL(`${config.apiUrl}/billings/prestations`);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  return fetch(url, requestOptions).then(handleResponse);
}

const downloadPreInvoice = (params) => {
  const requestOptions = {
    method: 'GET'
  };
  const url = new URL(`${config.apiUrl}/billings/companies/preInvoice`);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  return fetch(url, requestOptions).then(handleResponse);
}

const downloadOC = (params) => {
  const requestOptions = {
    method: 'GET'
  };
  const url = new URL(`${config.apiUrl}/billings/companies/purchaseOrder`);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  return fetch(url, requestOptions).then(handleResponse);
}

const checkFinished = (params) => {
  const requestOptions = {
    method: 'GET'
  };
  const url = new URL(`${config.apiUrl}/billings/checkFinished`);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  return fetch(url, requestOptions).then(handleResponse);
}

const fixObservation = (params) => {
  const requestOptions = {
    method: 'POST'
  };
  const url = new URL(`${config.apiUrl}/billings/fixObservation`);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  return fetch(url, requestOptions);
}

const addObservation = (params) => {
  const requestOptions = {
    method: 'POST'
  };
  const url = new URL(`${config.apiUrl}/billings/addObservation`);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  return fetch(url, requestOptions);
}

const authorize = (params) => {
  const requestOptions = {
    method: 'POST'
  };
  const url = new URL(`${config.apiUrl}/billings/authorize`);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  return fetch(url, requestOptions);
}

const uploadOC = (params) => {
  const requestOptions = {
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify(params)
  };
  const url = new URL(`${config.apiUrl}/billings/uploadOC`);
  return fetch(url, requestOptions);
}

export default {
  checkFinished,
  authorize,
  fixObservation,
  downloadOC,
  addObservation,
  downloadPreInvoice,
  fetchPrestations,
  fetchInvoices,
  fetchBillingById,
  fetchSummary,
  fetchCheck,
  openMonth,
  uploadOC
}