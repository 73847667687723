import { authHeader, handleResponse } from './helper';
import config from '../config';

const processNotProcessed = (params) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader()
    },
    body: JSON.stringify(params)
  };
  const url = new URL(`${config.apiUrl}/processes/fromNotProcessing`);
  return fetch(url, requestOptions).then(handleResponse);
}

const processInvoiceNotification = (params) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader()
    },
    body: JSON.stringify(params)
  };
  const url = new URL(`${config.apiUrl}/processes/invoiceNotification`);
  return fetch(url, requestOptions).then(handleResponse);
}

export default {
  processNotProcessed,
  processInvoiceNotification
}