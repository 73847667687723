import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Col, Row, Spin, Table, Button, Input, Checkbox } from 'antd';
import Payments from './Payments';
import invoiceService from '../../../services/invoiceService';
import { formatAmountByCurrency } from '../../../helpers';
import _ from 'lodash';

const Detail = ({
   countryId,
   companyId,
   billingId,
   isConfirmation,
   customComment,
   setCustomComment,
   reservationId,
   specialComment,
   quantity,
   type,
   payments,
   setPayments,
   setPaymentIsValid,
   setAttributes
}) => {
   const [prestations, setPrestations] = useState(false);
   const [prestationsLoading, setPrestationsLoading] = useState(false);
   const [discount, setDiscount] = useState(0);
   const [isCustomComment, setIsCustomComment] = useState(false);;
   const history = useHistory();
   const location = useLocation();

   const fetchServices = async () => {
      setPrestationsLoading(true);
      const oc = getOC();
      let params;

      if (type === 'agreement') {
         params = {
            companyId,
            billingId,
            type
         };

         if (oc) {
            params['oc'] = oc;
         }
      } else if (type === 'reservation') {
         params = {
            reservationId,
            type
         };
      } else {
         params = {
            reservationId,
            quantity,
            specialComment,
            type
         };
      }

      const response = await invoiceService.fetchServices(params);
      setPrestations(response);
      setPrestationsLoading(false);
      setDiscount(getDiscount(response));
   }

   useEffect(() => {
      fetchServices();
   }, []);

   const handleIsCustomComment = (event) => {
      setIsCustomComment(event.target.checked);

      if (!event.target.checked) {
         setCustomComment(null);
      }
   }

   const handleCustomComment = (event) => {
      setCustomComment(event.target.value);
   }

   const getOC = () => {
      const query = new URLSearchParams(location.search);
      return query.get('oc');
   }

   const columns = [
      {
         title: 'Prestación',
         dataIndex: 'prestation',
         key: 'prestation',
      },
      {
         title: 'Cantidad',
         dataIndex: 'count',
         key: 'count',
      },
      {
         title: 'Precio',
         dataIndex: 'amount',
         key: 'amount',
         render: (text, record) => {
            return (
               <div>
                  {text && formatAmountByCurrency({
                     countryCode: record.currency.locate_code,
                     currencyCode: record.currency.code,
                     amount: text
                  })}
               </div>
            );
         }
      },
      {
         title: <div className="text-bold" style={{ textAlign: 'right' }}>
            SubTotal
         </div>,
         dataIndex: 'sum',
         key: 'sum',
         render: (text, record) => {
            return (
               <div className="text-bold" style={{ textAlign: 'right' }}>
                  {text && formatAmountByCurrency({
                     countryCode: record.currency.locate_code,
                     currencyCode: record.currency.code,
                     amount: text
                  })}
               </div>
            );
         }
      },
   ]

   const goDocuments = () => {
      let oc = getOC();
      if (!oc) {
         if (type === 'agreement') {
            let searchParams = new URLSearchParams({ billingId, companyId, countryId });
            history.push({
               pathname: `/invoices/generator/${type}/documents`,
               search: searchParams.toString()
            });
         } else if (type === 'reservation') {
            let searchParams = new URLSearchParams({ reservationId, companyId, countryId });
            history.push({
               pathname: `/invoices/generator/${type}/documents`,
               search: searchParams.toString()
            });
         } else {
            let searchParams = new URLSearchParams({ reservationId, companyId, countryId, quantity, specialComment });
            history.push({
               pathname: `/invoices/generator/${type}/documents`,
               search: searchParams.toString()
            });
         }
      } else {
         let searchParams = new URLSearchParams({ billingId, companyId, countryId, oc });
         history.push({
            pathname: `/invoices/generator/${type}/documents`,
            search: searchParams.toString()
         });
      }
   };

   const getTotal = () => {
      const [prestation] = prestations;
      let total = prestation.total;
      return formatAmountByCurrency({
         countryCode: prestation.currency.locate_code,
         currencyCode: prestation.currency.code,
         amount: total
      })
   }

   const getDiscount = (response) => {
      return response[0].discount_total;
   }

   return (
      <Spin spinning={prestationsLoading}>
         <Row>
            <Col lg={24}>
               <h2>Detalle de factura</h2>

               {
                  prestations && (
                     <Table
                        pagination={false}
                        dataSource={isConfirmation && customComment ? [{
                           prestation: customComment,
                           count: 1,
                           sum: prestations[0].total,
                           amount: prestations[0].total,
                           currency: {
                              locate_code: prestations[0].currency.locate_code,
                              code: prestations[0].currency.code,
                           }
                        }] : prestations}
                        columns={columns}
                        summary={() => (
                           <Table.Summary.Row>
                              <Table.Summary.Cell index={0}></Table.Summary.Cell>
                              <Table.Summary.Cell index={1}></Table.Summary.Cell>
                              <Table.Summary.Cell index={2}>Total</Table.Summary.Cell>
                              <Table.Summary.Cell index={3}>
                                 {prestations && discount > 0 && (
                                    <div style={{ fontSize: 10, textAlign: 'right', color: 'grey' }}>
                                       {formatAmountByCurrency({
                                          countryCode: prestations[0].currency.locate_code,
                                          currencyCode: prestations[0].currency.code,
                                          amount: discount
                                       })}
                                    </div>
                                 )}
                                 <div className="text-bold" style={{ textAlign: 'right' }}>
                                    {prestations && getTotal()}
                                 </div>
                              </Table.Summary.Cell>
                           </Table.Summary.Row>
                        )}
                     />
                  )
               }
            </Col>
         </Row>

         {
            isConfirmation && prestations && (
               <Payments
                  countryId={countryId}
                  setAttributes={setAttributes}
                  prestations={prestations}
                  payments={payments}
                  setPayments={setPayments}
                  isConfirmation={isConfirmation}
                  setPaymentIsValid={setPaymentIsValid}
                  type={type}
               />
            )
         }

         {
            !isConfirmation && (
               <Row>
                  <Col lg={12}>
                     <Checkbox onChange={handleIsCustomComment}>Glosa Personalizada</Checkbox>

                     <Row className="mt-20">
                        <Col lg={12}>
                           {
                              isCustomComment && (
                                 <Input
                                    style={{ width: '100%' }}
                                    size="large"
                                    placeholder="Escribe aqui la glosa"
                                    onChange={handleCustomComment} />
                              )
                           }
                        </Col>
                     </Row>
                  </Col>
               </Row>
            )
         }

         {!isConfirmation && <Row justify="end">
            <Col>
               <Button
                  type="primary"
                  size="large"
                  onClick={goDocuments}
               >
                  Siguiente
               </Button>
            </Col>
         </Row>
         }
      </Spin>
   )
}

export default Detail;