import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import { PrivateRoute, PublicRoute } from '../components';
import ConsolidatorPage from '../ConsolidatorPage/ConsolidatorPage';
import PrestationListPage from '../PrestationListPage/PrestationListPage';
import InvoiceGeneratorPage from '../InvoiceGeneratorPage/InvoiceGeneratorPage';
import HomePage from '../HomePage/HomePage';
import GeneralInvoicePage from '../GeneralInvoicePage/GeneralInvoicePage';
import SpecialServiceInvoicePage from '../SpecialServiceInvoicePage/SpecialServiceInvoicePage';
import BillSpecialService from '../SpecialServiceInvoicePage/BillSpecialService';
import InvoiceFinder from '../InvoiceFinder/InvoiceFinder';
import Cookies from 'universal-cookie';

function App() {
   const cookies = new Cookies();
   const roles = cookies.get('roles');
   return (
      <Router>
         <Switch>
            <PrivateRoute exact path="/" component={roles && roles.includes('contabilidad') ? HomePage : GeneralInvoicePage} />
            <PrivateRoute exact path="/consolidator/:year/:month" component={ConsolidatorPage} />
            <PrivateRoute exact path="/consolidator/:year/:month/prestations/:companyId" component={PrestationListPage} private={true} />
            <PrivateRoute exact path="/invoices/generator/:type/detail" component={InvoiceGeneratorPage} step="detail" />
            <PrivateRoute exact path="/invoices/generator/:type/documents" component={InvoiceGeneratorPage} step="documents" />
            <PrivateRoute exact path="/general" component={GeneralInvoicePage} />
            <PrivateRoute exact path="/otros-servicios" component={SpecialServiceInvoicePage} />
            <PrivateRoute exact path="/otros-servicios/facturar" component={BillSpecialService} />
            <PrivateRoute exact path="/buscador" component={InvoiceFinder} />
            <PublicRoute exact path="/prefactura/:billingCode/:companyCode" component={PrestationListPage} private={false} />
            <Redirect from="*" to="/" />
         </Switch>
      </Router>
   );
}

export { App };