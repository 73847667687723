import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Form, Input, Button, message, Table, Modal, Select } from 'antd';
import { specialServiceInvoiceService } from '../services/specialServiceInvoice';
import humanizeList from 'humanize-list'
import moment from 'moment';

const BillSpecialService = () => {
   const [loading, setLoading] = useState(false);
   const [reservationDetails, setReservationDetails] = useState();
   const [isModifyCompanyModalVisible, setIsModifyCompanyModalVisible] = useState(false);
   const [isModifyServicesModalVisible, setIsModifyServicesModalVisible] = useState(false);
   const [form] = Form.useForm();
   const history = useHistory();

   const onFinish = async values => {
      try {
         setLoading(true);
         const response = await specialServiceInvoiceService.reservationDetails(values);
         if (response.id) {
            setReservationDetails(response);
            setLoading(false);
         } else {
            message.error({
               content: 'La reserva ingresada no existe.',
               style: {
                  marginTop: '40vh'
               }
            });
            setLoading(false);
         }
      } catch(err) {
         console.error(err);
         message.error('Error interno', err);
         setLoading(false);
      }
   };

   const formatDate = (date) => {
      let format = moment(date, 'YYYY-MM-DD').locale("es").format('dddd, DD [de] MMMM [de] YYYY');
      const splitDate = format.split(",");
      const lowerCaseDayName = splitDate[0].toLowerCase();
      const capitalizeDayName = lowerCaseDayName.replace(/\b(\w)/g, s => s.toUpperCase());
      const dateFormat = splitDate[1];
      return `${capitalizeDayName}, ${dateFormat}`;
   };

   const columns = [
      {
         title: 'Prestación',
         dataIndex: 'service',
         key: 'service'
      },
      {
         title: 'Trabajador',
         dataIndex: 'patient_full_name',
         key: 'patient'
      },
      {
         title: 'Resultado',
         dataIndex: 'result',
         key: 'results'
      }
   ];

   const showModifyCompanyModal = () => {
      !isModifyCompanyModalVisible ? setIsModifyCompanyModalVisible(true) : setIsModifyCompanyModalVisible(false);
   };

   const closeModifyCompanyModal = () => {
      setIsModifyCompanyModalVisible(false);
   };

   const showModifyServicesModal = () => {
      !isModifyServicesModalVisible ? setIsModifyServicesModalVisible(true) : setIsModifyServicesModalVisible(false);
   };

   const closeModifyServicesModal = () => {
      setIsModifyServicesModalVisible(false);
   };

   const modifyCompany = async values => {
      try {
         setLoading(true);
         const specialComment = "modifyCompany";
         history.push(
            `/invoices/generator/service/detail?reservationId=${reservationDetails.reservation_number}&quantity=${values.reportsQuantity}&specialComment=${specialComment}`
         );
         setLoading(false);
      } catch(err) {
         console.error(err);
         message.error('Error interno.', err);
         setLoading(false);
      }
   };

   const modifyServices = async values => {
      try {
         setLoading(true);
         const specialComment = "modifyBatteries";
         history.push(
            `/invoices/generator/service/detail?reservationId=${reservationDetails.reservation_number}&quantity=${values.servicesQuantity}&specialComment=${specialComment}`
         );
         setLoading(false);
      } catch(err) {
         console.error(err);
         message.error('Error interno.', err);
         setLoading(false);
      }
   };

   return (
      <Row justify="center">
         <Col lg={24} xs={24} className="mt-30">
            <Row justify="center">
               <Col lg={24} xs={24}>
                  <h1 className="text-center">Facturar otros servicios</h1>
               </Col>
            </Row>
            {
               !reservationDetails ? 
                  <Row justify="center">
                     <Col lg={7} xs={22}>
                        <Form
                           form={form}
                           onFinish={onFinish}
                           layout="vertical"
                           style={{ width: '100%'}}
                           size="large"
                        >
                           <Row justify="center">
                              <Col lg={24}>
                                 <Form.Item 
                                    label="Ingrese el n° de reserva" 
                                    name="reservationNumber"
                                    rules={[
                                       {
                                          required: true,
                                          message: 'El n° de reserva es requerido.'
                                       }
                                    ]}
                                 >
                                    <Input placeholder="N° de reserva" />
                                 </Form.Item>
                              </Col>
                              <Col lg={12} xs={22} className="mt-40">
                                 <Button loading={loading} htmlType="submit" type="primary" block>Continuar</Button>
                              </Col>
                           </Row>
                        </Form>
                     </Col>
                  </Row>
                  :
                  <Row justify="center">
                     <Col lg={24} xs={24}>
                        <Row justify="center" className="pt-30 pl-30 pr-30 pb-30">
                           <Col lg={24} xs={24}>
                              <h1>Reserva n° {reservationDetails.reservation_number}</h1>
                           </Col>
                           <Col lg={24} xs={24}>
                              <Row>
                                 <Col lg={12}>
                                    <Row>
                                       <Col className="mr-10">
                                          <p>Sucursal:</p>
                                       </Col>
                                       <Col>
                                          <p>{reservationDetails.reservation_details.branch_office}</p>
                                       </Col>
                                    </Row>
                                 </Col>
                                 <Col lg={12}>
                                    <Row>
                                       <Col className="mr-10">
                                          <p>Empresa:</p>
                                       </Col>
                                       <Col>
                                          <p>{reservationDetails.reservation_details.company_name}</p>
                                       </Col>
                                    </Row>
                                 </Col>
                              </Row>
                              <Row>
                                 <Col lg={12}>
                                    <Row>
                                       <Col className="mr-10">
                                          <p>Fecha:</p>
                                       </Col>
                                       <Col>
                                          <p>{formatDate(reservationDetails.reservation_details.date)}</p>
                                       </Col>
                                    </Row>
                                 </Col>
                                 <Col lg={12}>
                                    <Row>
                                       <Col className="mr-10">
                                          <p>Solicitante:</p>
                                       </Col>
                                       <Col>
                                          <p>{reservationDetails.reservation_details.applicant}</p>
                                       </Col>
                                    </Row>
                                 </Col>
                              </Row>
                              <Row>
                                 <Col lg={24}>
                                    <Row>
                                       <Col className="mr-10">
                                          <p>Hora:</p>
                                       </Col>
                                       <Col>
                                          <Row>
                                             {
                                                humanizeList(
                                                   reservationDetails.reservation_details.blocks.map(block => {
                                                      return `${block.block} hs`
                                                   }),
                                                   {conjunction: 'y'}
                                                )
                                             }
                                          </Row>
                                       </Col>
                                    </Row>
                                 </Col>
                              </Row>
                           </Col>
                        </Row>
                        <Row justify="center" className="mt-60">
                           <Col lg={24}>
                              <h3>Resultados de prestaciones realizadas</h3>
                           </Col>
                           <Col lg={24}>
                              <Row justify="start">
                                 <Col lg={20}>
                                    <Table columns={columns} dataSource={reservationDetails.services_results} pagination={false} bordered={false} />
                                 </Col>
                              </Row>
                           </Col>
                        </Row>
                        <Row justify="center" className="mt-50 mb-70">
                           <Col lg={10}>
                              <Row gutter={15}>
                                 <Col lg={10}>
                                    <Button onClick={showModifyCompanyModal} type="primary" size="large" block>Cambiar razón social</Button>
                                 </Col>
                                 <Col lg={10}>
                                    <Button onClick={showModifyServicesModal} type="primary" size="large" block>Cambiar baterías</Button>
                                 </Col>
                              </Row>
                           </Col>
                        </Row>
                        <Row>
                           <Modal
                              closable={false}
                              footer={false}
                              width={500}
                              maskClosable={true}
                              onCancel={closeModifyCompanyModal}
                              visible={isModifyCompanyModalVisible}
                           >
                              <Form
                                 form={form}
                                 onFinish={modifyCompany}
                                 style={{ width: '100%'}}
                                 size="large"
                              >
                                 <Col lg={24} className="pt-20 pb-30 pr-10 pl-10">
                                    <Row justify="center">
                                       <Col lg={24}>
                                          <h3 className="text-center">¿A cuántos informes desea cambiar la razón social?</h3>
                                       </Col>
                                       <Col lg={14}>
                                          <Form.Item initialValue="1" label="Cantidad de informes" name="reportsQuantity">
                                             <Select>
                                                <Select.Option value="1">1</Select.Option>
                                                <Select.Option value="2">2</Select.Option>
                                                <Select.Option value="3">3</Select.Option>
                                                <Select.Option value="4">4</Select.Option>
                                                <Select.Option value="5">5</Select.Option>
                                             </Select>
                                          </Form.Item>
                                       </Col>
                                       <Col lg={24}>
                                          <Row justify="center" gutter={15}>
                                             <Col lg={8}>
                                                <Button onClick={closeModifyCompanyModal} type="primary" block>Cancelar</Button>
                                             </Col>
                                             <Col lg={10}>
                                                <Button loading={loading} htmlType="submit" type="primary" block>Continuar</Button>
                                             </Col>
                                          </Row>
                                       </Col>
                                    </Row>
                                 </Col>
                              </Form>
                           </Modal>

                           <Modal
                              closable={false}
                              footer={false}
                              width={500}
                              maskClosable={true}
                              onCancel={closeModifyServicesModal}
                              visible={isModifyServicesModalVisible}
                           >
                              <Form
                                 form={form}
                                 onFinish={modifyServices}
                                 style={{ width: '100%'}}
                                 size="large"
                              >
                                 <Col lg={24} className="pt-20 pb-30 pr-10 pl-10">
                                    <Row justify="center">
                                       <Col lg={24}>
                                          <h3 className="text-center">¿Cuántas baterías desea cambiar?</h3>
                                       </Col>
                                       <Col lg={14}>
                                          <Form.Item initialValue="1" label="Cantidad de baterías" name="servicesQuantity">
                                             <Select>
                                                <Select.Option value="1">1</Select.Option>
                                                <Select.Option value="2">2</Select.Option>
                                                <Select.Option value="3">3</Select.Option>
                                                <Select.Option value="4">4</Select.Option>
                                                <Select.Option value="5">5</Select.Option>
                                             </Select>
                                          </Form.Item>
                                       </Col>
                                       <Col lg={24}>
                                          <Row justify="center" gutter={15}>
                                             <Col lg={8}>
                                                <Button onClick={closeModifyServicesModal} type="primary" block>Cancelar</Button>
                                             </Col>
                                             <Col lg={10}>
                                                <Button loading={loading} htmlType="submit" type="primary" block>Continuar</Button>
                                             </Col>
                                          </Row>
                                       </Col>
                                    </Row>
                                 </Col>
                              </Form>
                           </Modal>
                        </Row>
                     </Col>
                  </Row>
            }
         </Col>
      </Row>
   );
};

export default BillSpecialService;