import React, { useEffect, useState } from 'react';
import { Table, Spin, Radio } from 'antd';
import { generalInvoiceService } from '../../services/generalInvoice';
import InvoiceDetail from './InvoiceDetail';
import CompanyDetail from './CompanyDetail';
import ReservationDetail from './ReservationDetail';
import { formatAmountByCurrency, setTotalAmount, formatDate, formatAmount } from '../../helpers';

const { format } = require('rut.js');

const ReservationsToBill = ({ filters }) => {
   const [loading, setLoading] = useState(false);
   const [reservations, setReservations] = useState();
   const [currentPage, setCurrentPage] = useState(1);

   const fetchReservations = async (params) => {
      setLoading(true);
      const response = await generalInvoiceService.billedReservations(params);
      setReservations(response.data);
      setLoading(false);
   };

   useEffect(() => {
      const extra = { page: 1 }
      fetchReservations({ ...filters, ...extra });
   }, [filters]);

   const loadMore = (e) => {
      const direction = e.target.value
      const _currentPage = direction == 'next' ? currentPage + 1 : currentPage - 1
      const extra = { page: _currentPage }
      fetchReservations({ ...filters, ...extra })
      setCurrentPage(_currentPage)
   }

   const setTotal = (record, total) => {
      setTotalAmount(record, total, reservations, setReservations);
   }

   const columns = [
      {
         title: 'N° de reserva',
         dataIndex: 'number',
         key: 'number',
         render: (text, record) => {
            return <CompanyDetail text={text} record={record} />
         }
      },
      {
         title: 'Fecha',
         dataIndex: 'reservation_date',
         key: 'reservation_date',
         render: (text) => {
            return <p className="mb-0">{formatDate(text)}</p>
         }
      },
      {
         title: 'Monto',
         dataIndex: 'amount_total',
         key: 'amount_total',
         render: (text, record) => {
            return <p className="mb-0">
               {text && formatAmountByCurrency({
                  countryCode: record.currency.locate_code,
                  currencyCode: record.currency.code,
                  amount: text
               })}
            </p>
         }
      },
      {
         title: 'Detalle (prestación/cantidad)',
         dataIndex: 'detail',
         key: 'detail',
         render: (text, record) => {
            return <ReservationDetail record={record} setTotal={setTotal} invoiced={true} />
         }
      },
      {
         title: 'Método de pago',
         dataIndex: 'payment_method',
         key: 'payment_method',
      },
      {
         title: 'Facturas asociadas',
         dataIndex: 'invoice_details',
         key: 'invoice_details',
         render: (text, record) => {
            return <InvoiceDetail text={text} record={record} />
         }
      }
   ];

   return (
      <React.Fragment>
         {
            loading ?
               <Spin style={{ display: 'flex', justifyContent: 'center' }} />
               :
               reservations &&
               <React.Fragment>
                  <Table columns={columns} dataSource={reservations} pagination={false} bordered={false} />

                  <Radio.Group value={'large'} onChange={loadMore} className="mb-20">
                     <Radio.Button value="prev" disabled={currentPage == 1}>Anterior</Radio.Button>
                     <Radio.Button value="next" disabled={!reservations.length}>Siguiente</Radio.Button>
                  </Radio.Group>
               </React.Fragment>
         }
      </React.Fragment>
   );
};

export default ReservationsToBill;