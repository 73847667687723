import { authHeader, handleResponse } from './helper';
import config from '../config';

const ENDPOINT_URL = 'https://run.mocky.io/v3';

const getInvoices = async (params) => {
   const url = new URL(`${config.apiUrl}/invoicesFinder/invoices`);
   Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
   const response = await fetch(url, {
      method: 'GET',
      headers: authHeader()
   });
   const dataResponse = response.json();
   return dataResponse;
};

const deleteInvoice = (params) => {
   const requestOptions = {
      method: 'POST',
      headers: {
         'Content-Type': 'application/json',
         ...authHeader()
      },
      body: JSON.stringify(params)
   };
   const url = new URL(`${config.apiUrl}/invoicesFinder/delete`);
   return fetch(url, requestOptions).then(handleResponse);
};

export default {
   getInvoices,
   deleteInvoice
}