import React, { useState } from 'react';
import { Modal, Row, Col, Form, Button, Select, message, Input, Spin } from 'antd';
import { generalInvoiceService } from '../../services/generalInvoice';
import moment from 'moment';

const AssociateInvoice = ({ reservationData, isModalVisible, refreshData, closeModal }) => {
  const [findInvoiceLoading, setFindInvoiceLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [invoice, setInvoice] = useState();
  const [form] = Form.useForm();

  const handleAssociate = (values) => {
    setLoading(true);
    generalInvoiceService
      .associateInvoiceToReservation({
        countryId: reservationData.countryId,
        reservationId: reservationData.reservationId,
        invoiceNumber: invoice.number
      })
      .then(response => {
        isModalVisible = false;
        setLoading(false);
        refreshData();
        closeModal();
        message.success({
          content: 'Factura asociada exitosamente.',
          style: {
            marginTop: '40vh'
          }
        });
      })
  };

  const onFinish = async (values) => {
    setInvoice(null);
    setFindInvoiceLoading(true);

    generalInvoiceService
      .getExternalInvoice({
        countryId: reservationData.countryId,
        invoiceNumber: values.invoiceNumber,
        reservationId: reservationData.reservationId
      })
      .then(response => {
        if (response.exists) {
          message.error('Esta factura ya esta asociada a alguna reserva');
        } else if (!Object.keys(response).length) {
          message.error('Factura no encontrada');
        } else {
          setInvoice(response);
        }
      })
      .catch(error => {
        message.error('Factura no encontrada');
      })
      .finally(() => {
        setFindInvoiceLoading(false);
      })
  }

  const formatDate = (date) => {
    return moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY');
  };

  const formatAmount = (amount) => {
    return new Number(amount).toLocaleString('es-CL');
  };

  return (
    <Modal
      width={600}
      footer={false}
      maskClosable={true}
      closable={false}
      visible={isModalVisible}
      onCancel={closeModal}
    >
      <Row justify="center">
        <Col lg={22} xs={22}>
          <Row justify="start">
            <Col lg={24} xs={24}>
              <h2>Asociar factura externa a reserva n° {reservationData && reservationData.reservationNumber}</h2>
            </Col>
            <Col lg={20} xs={20} className="mb-20">
              <Form
                onFinish={onFinish}
                layout="vertical"
                style={{ width: '100%' }}
                size="large"
              >
                <Row justify="space-between" gutter={10} align="bottom">
                  <Col lg={15} xs={15}>
                    <Form.Item
                      name="invoiceNumber"
                      label="Ingrese el número de la factura"
                      className="mb-0"
                      rules={[
                        {
                          required: true,
                          message: 'N° de factura es requerido.'
                        }
                      ]}
                    >
                      <Input placeholder="N° de factura" />
                    </Form.Item>
                  </Col>
                  <Col lg={8} xs={8}>
                    <Button loading={findInvoiceLoading} htmlType="submit" type="primary" block>Buscar</Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Col>
        {
          loading ?
            <Spin style={{ display: 'flex', justifyContent: 'center' }} />
            :
            invoice && (
              <Col lg={22} xs={22} className="mt-20">
                <Form
                  form={form}
                  onFinish={handleAssociate}
                  size="large"
                >
                  <Row>
                    <Col className="mr-10">
                      <span>Empresa: </span> <strong>{reservationData && reservationData.companyName}</strong>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mr-10 mt-10">
                      <span>Monto: </span>
                      <strong>$ {formatAmount(invoice.totalAmount)}</strong>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mr-10 mt-10 mb-20">
                      <span>Fecha: </span>
                      <strong>{formatDate(invoice.emissionDate)}</strong>
                    </Col>
                  </Row>
                  <Row justify="center">
                    <Col lg={8} xs={8}>
                      <Button htmlType="submit" type="primary" block>Asociar factura</Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            )
        }
      </Row>
    </Modal>
  )
}

export default AssociateInvoice;