import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Row, Col, Button, Spin } from 'antd';
import billingService from '../../services/billingService';

const TIMEOUT = 10000;

const MonthSelector = ({ date, beforeMonth, nextMonth }) => {
  const [progress, setProgress] = useState();
  const [isFinished, setIsFinished] = useState(false);
  const handleBeforeMonth = () => {
    beforeMonth();
  }

  const handleNextMonth = () => {
    nextMonth();
  }

  const fetchCheckFinished = async () => {
    const response = await billingService.checkFinished({
      year: date.year(),
      month: date.month() + 1
    });
    setProgress(response)
    setIsFinished(response[0] == response[1]);
  }

  // useEffect(() => {
  //   if (!isFinished) {
  //     fetchCheckFinished();
  //     setTimeout(() => {
  //       const interval = setInterval(async () => {
  //         await fetchCheckFinished();
  //       }, TIMEOUT);

  //       return () => clearInterval(interval);
  //     }, TIMEOUT);
  //   }
  // }, [])

  return (
    <Row justify="center" className="month-selector">
      <Col lg={6} xs={24}>
        <div className="title">
          {date.format('MMMM')} {date.format('YYYY')}
          {
            progress && progress[1] < progress[0] && (
              <span className="ml-10">
                <Spin />
              </span>
            )
          }
        </div>
        <div className="selection">
          <Row justify="center" >
            <Col lg={12}>
              <Button type="link" onClick={handleBeforeMonth}>
                <LeftOutlined /> Mes Anterior
              </Button>
            </Col>
            <Col lg={12}>
              <Button type="link" onClick={handleNextMonth}>
                Mes Siguiente <RightOutlined />
              </Button>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
}

export default MonthSelector;