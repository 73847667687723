import React, { useState, useEffect } from "react";
import { Collapse, Table, Button, Tag, Spin } from "antd";
import { Link } from 'react-router-dom';
import moment from "moment";
import _ from "lodash";
import AssociateInvoice from '../components/AssociateInvoice';
import billingService from '../../services/billingService';
import invoiceServoce from '../../services/invoiceService';
import { formatAmountByCurrency } from '../../helpers';

const { Panel } = Collapse;

const WithOCTable = ({
  isPrivate,
  companyId,
  billing,
  agreementType,
  withOrders,
  setId,
  month,
  year,
  loading
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [groupedOrders, setGroupedOrders] = useState()

  const downloadOC = async (event, id) => {
    event.stopPropagation();
    const response = await billingService.downloadOC({ id });
    window.open(response.url, '_blank');
  }

  const checkOCInvoiced = async (id) => {
    const response = await invoiceServoce.checkOCInvoiced({ id });
    return response.invoiced;
  }

  const columns = [
    {
      width: 100,
      title: 'Reserva y Sucursal',
      dataIndex: 'number',
      key: 'number',
      render: (text, record) => {
        return (
          <React.Fragment>
            <div className="text-bold">{text}</div>
            <div>
              <Tag>{record.branch_name}</Tag>
            </div>
          </React.Fragment>
        )
      }
    },
    {
      width: 60,
      title: 'Fecha',
      dataIndex: 'admission_date',
      key: 'admission_date',
      render: (text) => {
        return moment(text, 'YYYY-MM-DD_HH-mm-ssZ').format('YYYY-MM-DD')
      }
    },
    {
      width: 300,
      title: 'Paciente',
      dataIndex: 'patient_name',
      key: 'patient_name',
      render: (text, record) => {
        return (
          <React.Fragment>
            <div>{text}</div>
            <div>{record.patient_document}</div>
          </React.Fragment>
        )
      }
    },
    {
      width: 300,
      title: 'Prestación',
      dataIndex: 'prestation_name',
      key: 'prestation_name',
      render: (text, record) => {
        return (
          <React.Fragment>
            <div>{text}</div>
          </React.Fragment>
        )
      }
    },
    {
      width: 300,
      title: 'Solicitante',
      dataIndex: 'user_name',
      key: 'user_name',
      render: (text, record) => {
        return (
          <React.Fragment>
            <div>{text}</div>
            {
              record.cost_center_name && (
                <div>
                  {`C.C `}
                  <Tag>
                    {record.cost_center_name}
                  </Tag>
                </div>
              )
            }
          </React.Fragment>
        )
      }
    },
    {
      title: 'Monto',
      dataIndex: 'amount',
      key: 'amount',
      render: (text, record) => {
        return (
          <div className="text-bold">
            {
              record.discount_amount > 0 && <div style={{ fontSize: 10, textAlign: 'right', color: 'grey' }}>
                {formatAmountByCurrency({
                  countryCode: record.currency.locate_code,
                  currencyCode: record.currency.code,
                  amount: record.sub_amount
                })}
              </div>
            }
            <div style={{ textAlign: 'right' }}>
              {formatAmountByCurrency({
                countryCode: record.currency.locate_code,
                currencyCode: record.currency.code,
                amount: text
              })}
            </div>
          </div>
        );
      }
    },
  ]

  const updateOrders = async () => {
    setIsLoading(true);
    const ids = Object.keys(_.groupBy(withOrders, 'purchase_order_id'));

    for await (let id of ids) {
      let isInvoiced = await checkOCInvoiced(id);
      for await (let item of withOrders) {
        if (id == item.purchase_order_id) {
          item['isInvoiced'] = isInvoiced;
        }
      }
    }

    setIsLoading(false);
    setGroupedOrders(_.groupBy(withOrders, 'oc_number'))
  }

  useEffect(() => {
    updateOrders();
  }, [])

  return (
    <Spin spinning={isLoading}>
      {
        agreementType == 'Orden de Compra' && <Collapse defaultActiveKey={[0]} className="mt-20">
          {
            groupedOrders && Object.entries(groupedOrders).map((item, index) => {
              return (
                <Panel header={
                  <>
                    <span className="text-bold">
                      Nº {item[0]}&nbsp;&nbsp;
                    </span>
                    <span>
                      {formatAmountByCurrency({
                        countryCode: item[1][0].currency.locate_code,
                        currencyCode: item[1][0].currency.code,
                        amount: item[1].map(i => { return parseFloat(i.amount) }).reduce((a, b) => a + b, 0)
                      })}
                    </span>
                  </>
                }
                  key={index}
                  extra={
                    <React.Fragment>
                      {
                        isPrivate && (
                          <React.Fragment>
                            {
                              item[1][0]['isInvoiced'] ? (
                                <Button className="mr-10" disabled={true}>Facturado</Button>
                              ) : (
                                <React.Fragment>
                                  <AssociateInvoice
                                    actionSize="medium"
                                    month={month}
                                    year={year}
                                    billing={billing}
                                    setId={setId}
                                    oc={item[1][0]['purchase_order_id']}
                                  />
                                  <Link to={{
                                    pathname: `/invoices/generator/agreement/detail`,
                                    search: `?companyId=${companyId}&billingId=${billing.id}&oc=${item[1][0]['purchase_order_id']}&countryId=${billing.currency.country_id}`
                                  }}>

                                    <Button
                                      type="primary"
                                      className="mr-10">
                                      Facturar
                                    </Button>
                                  </Link>
                                </React.Fragment>
                              )
                            }
                          </React.Fragment>
                        )
                      }

                      <Button type="primary" onClick={(event) => {
                        return downloadOC(event, item[1][0]['purchase_order_id']);
                      }}>
                        Descargar OC
                      </Button>
                    </React.Fragment>
                  }
                >
                  <Table dataSource={item[1]}
                    columns={_.reject(columns, { key: 'check' })}
                    loading={loading}
                    pagination={false}
                    className="prestation-table"
                  />
                </Panel>
              )
            })
          }
        </Collapse>
      }
    </Spin>
  )
}

export default WithOCTable;