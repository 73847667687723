import { authHeader, handleResponse } from "./helper";
import config from '../config';

const ENDPOINT_URL = 'https://run.mocky.io/v3';

const invoices = async (params) => {
   const url = new URL(`${ENDPOINT_URL}/91aaeb99-32ac-4fe8-8098-5cfd2b7dd494`);
   Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
   const response = await fetch(url, {
      method: 'GET',
      headers: {
         'Content-Type': 'application/json'
      }
   });
   const dataResponse = response.json();
   return dataResponse;
};

const deleteInvoice = async (params) => {
   const url = new URL (`${ENDPOINT_URL}/8888b56f-78f0-4778-a1b6-970981445e11`);
   const response = await fetch(url, {
      method: 'DELETE',
      headers: {
         'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
   });
   const dataResponse = response.json();
   return dataResponse;
};

const reservationDetails = async (params) => {
   const url = new URL (`${ENDPOINT_URL}/6ed6201e-b78f-4b1a-82ba-989f1ef28147`);
   Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
   const response = await fetch(url, {
      method: 'GET',
      headers: {
         'Content-Type': 'application/json'
      }
   });
   const dataResponse = response.json();
   return dataResponse;
};

export const specialServiceInvoiceService = {
   invoices,
   deleteInvoice,
   reservationDetails,
};