import { Row, Col, Card } from 'antd';
import moment from 'moment';
import 'moment/locale/es';

const HeaderCard = ({ prestations, month, year }) => {
  const item = prestations[0];
  const date = moment().set({
    month: item.month - 1,
    year: item.year
  })

  return (
    <Row gutter={20}>
      <Col lg={12}>
        <Card className="full-width">
          <div>Empresa: <span className="text-bold">{item.company_name}</span></div>
          <div>RUT: <span className="text-bold">{item.company_rut}</span></div>
        </Card>
      </Col>
      <Col lg={12}>
        <Card className="full-width">
          <div>
            Periodo de prestaciones
          </div>
          <div className="text-bold">
            {date.local('es').format('MMMM')} {date.format('YYYY')}
          </div>
        </Card>
      </Col>
    </Row>
  )
}

export default HeaderCard;