import React, { useState, useEffect } from 'react';
import { Spin, Row, Col } from 'antd';
import invoiceService from '../../services/invoiceService';

const Info = ({ companyId, billingId, type, reservationId }) => {
   const [companyData, setCompanyData] = useState({});
   const [companyLoading, setCompanyLoading] = useState(false);

   const fetchCompanyInvoiceData = async () => {
      setCompanyLoading(true);

      const params = {
         companyId,
         billingId,
      };

      const response = await invoiceService.fetchCompanyInfo(params);
      setCompanyData(response);
      setCompanyLoading(false);
   }

   useEffect(() => {
      fetchCompanyInvoiceData();
   }, []);

   return (
      <Spin spinning={companyLoading}>
         <Row className="mb-40">
            <Col lg={24} className="invoice-generator-header">
               <h1 className="text-center mb-40">Factura a nombre de <br /><span>{companyData.business_name}</span></h1>
               <Row gutter={[0, 0]}>
                  <Col lg={12}>
                     <div>
                        Razon Social: <span className="text-bold">{companyData.business_name}</span>
                     </div>
                     <div>
                        Direccion: <span className="text-bold">{companyData.address}</span>
                     </div>
                  </Col>
                  <Col lg={12}>
                     <div>
                        {companyData && companyData.document_type && companyData.document_type.toUpperCase()}: <span className="text-bold">{companyData.document_number}</span>
                     </div>
                     <div>
                        Ciudad: <span className="text-bold">{companyData.city}</span>
                     </div>
                  </Col>
                  <Col lg={12}>
                     <div>
                        Comuna: <span className="text-bold">{companyData.commune}</span>
                     </div>
                  </Col>
                  <Col lg={12}>
                     <div>
                        Giro: <span className="text-bold">{companyData.activity}</span>
                     </div>
                  </Col>
               </Row>
            </Col>
         </Row>
      </Spin>
   )
}

export default Info;