import Cookies from 'universal-cookie';
import config from '../config';

export function authHeader() {
  const cookies = new Cookies();
  let token = cookies.get('user');
  let country = cookies.get('country_code');
  if (token) {
    return {
      'Authorization': `Bearer ${token}`,
      'country': country
    };
  }

  return {};
}

export function countryHeader() {
  const cookies = new Cookies();
  let country = cookies.get('country_code');
  if (country) {
    return { 'country': country };
  }

  return {};
}

function logout() {
  const cookies = new Cookies();
  cookies.remove('user');
}

export function handleResponse(response) {
  return response.text().then(text => {
    let data = {};

    try {
      data = text && JSON.parse(text);
    } catch (error) {
      console.log(error);
    }


    if (!response.ok) {
      if (response.status === 401) {
        logout();
        window.location.href = config.loginRedirect;
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

export default handleResponse;