import React, { useState, useEffect } from "react";
import moment from 'moment';
import _ from 'lodash';
import { Tabs } from 'antd';

import PrestationTable from './PrestationTable';
import WithOCTable from './WithOCTable';
import WithObservationTable from './WithObservationsTable';

const { TabPane } = Tabs;

const rejectReasonOptions = [
  {
    label: 'Precio no corresponde',
    value: 'incorrect_price'
  },
  {
    label: 'Paciente pertenece a otra empresa del grupo o holding',
    value: 'patient_from_another_holding_company'
  },
  {
    label: 'Servicio duplicado',
    value: 'duplicate_service'
  },
  {
    label: 'No se identifica centro de costo',
    value: 'no_cost_center'
  },
  {
    label: 'Paciente no pertenece a la empresa',
    value: 'unidentified_patient'
  },
  {
    label: 'Usuario solicitante no pertenece a la empresa',
    value: 'unidentified_user'
  },
  {
    label: 'Otros motivos',
    value: 'other_reason'
  },
];

const PrestationTabs = ({
  companyId,
  billing,
  purchaseOrders,
  prestations,
  withOrders,
  withObservations,
  loading,
  setId,
  month,
  year,
  uploadOC,
  associateOC,
  agreementType,
  isPrivate
}) => {
  return (
    <div className="mb-60">
      <Tabs defaultActiveKey="1">
        <TabPane tab="Detalle de prestaciones" key="1">
          <PrestationTable
            billing={billing}
            isPrivate={isPrivate}
            purchaseOrders={purchaseOrders}
            agreementType={agreementType}
            rejectReasonOptions={rejectReasonOptions}
            prestations={prestations}
            setId={setId}
            uploadOC={uploadOC}
            associateOC={associateOC}
            loading={loading}
          />
        </TabPane>

        {
          withOrders && withOrders.length && <TabPane tab="Ordenes de compra cargadas" key="2">
            <WithOCTable
              setId={setId}
              month={month}
              year={year}
              isPrivate={isPrivate}
              companyId={companyId}
              billing={billing}
              withOrders={withOrders}
              loading={loading}
              agreementType={agreementType}
            />
          </TabPane>
        }

        {
          withObservations && withObservations.length && <TabPane tab="Prestaciones observadas" key="3">
            <WithObservationTable
              isPrivate={isPrivate}
              rejectReasonOptions={rejectReasonOptions}
              withObservations={withObservations}
              loading={loading}
              setId={setId}
              billing={billing}
            />
          </TabPane>
        }
      </Tabs>
    </div >
  )
}

export default PrestationTabs;