import { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import DetailCard from './DetailCard';
import billingInvoice from '../../services/billingService';

const Summary = ({ date, id }) => {
  const [notProcessing, setNotProcessing] = useState();
  const [waitingOC, setWaitingOC] = useState();
  const [waitingAuthorization, setWaitingAuthorization] = useState();
  const [readyToBill, setReadyToBill] = useState();
  const [invoiced, setInvoiced] = useState();
  const [loading, setLoading] = useState(false);

  const fetchSummary = async () => {
    setLoading(true);
    const response = await billingInvoice.fetchSummary({
      month: date.month() + 1,
      year: date.year()
    });

    setLoading(false);
    setNotProcessing(setItems('not_processing', response));
    setWaitingOC(setItems('waiting_oc', response));
    setWaitingAuthorization(setItems('waiting_authorization', response))
    setReadyToBill(setItems('ready_to_bill', response))
    setInvoiced(setItems('invoiced', response))
  }

  const setItems = (state, items) => {
    return items.filter(item => item.state == state)[0];
  }

  useEffect(() => {
    fetchSummary();
  }, [date, id])

  return (
    <Row gutter={0} className="mt-20" justify="space-between">
      <Col lg={4}>
        <DetailCard text="Sin procesar"
          state="not_processing"
          data={notProcessing}
          loading={loading} />
      </Col>
      <Col lg={4}>
        <DetailCard text="Esperando OC"
          state="waiting_oc"
          data={waitingOC}
          loading={loading} />
      </Col>
      <Col lg={4}>
        <DetailCard text="Esperando autorizacion"
          state="waiting_authorization"
          data={waitingAuthorization}
          loading={loading} />
      </Col>
      <Col lg={4}>
        <DetailCard text="Listo para Facturar"
          state="ready_to_bill"
          data={readyToBill}
          loading={loading} />
      </Col>
      <Col lg={4}>
        <DetailCard text="Facturado"
          state="invoiced"
          data={invoiced}
          loading={loading} />
      </Col>
    </Row>
  )
}

export default Summary;