import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Table, Button, Form, Input, message, Spin } from 'antd';
import { specialServiceInvoiceService } from '../services/specialServiceInvoice';
import moment from 'moment';

const SpecialServiceInvoicePage = () => {
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState();
  const [refresh, setRefresh] = useState();
  const [form] = Form.useForm();

  const fetchInvoices = async (params) => {
    setLoading(true);
    const response = await specialServiceInvoiceService.invoices(params);
    setInvoices(response);
    setLoading(false);
  };

  useEffect(() => {
    fetchInvoices({});
  }, [refresh]);

  const onFinish = async values => {
    try {
      setLoading(true);
      const response = await fetchInvoices(values);
      if (!response.id) {
        message.error({
          content: 'No existe factura asociada a la búsqueda.',
          style: {
            marginTop: '40vh'
          }
        });
        setLoading(false);
      }
    } catch (err) {
      console.error(err);
      message.error('Error interno.', err);
      setLoading(false);
    }
  };

  const columns = [
    {
      title: 'N° de factura',
      dataIndex: 'invoice_details',
      key: 'invoice_number',
      render: (text, record) => {
        return <p className="mb-0">{record.invoice_details.invoice_number}</p>
      }
    },
    {
      title: 'Fecha',
      dataIndex: 'date',
      key: 'date',
      render: (text) => {
        return <p className="mb-0">{formatDate(text)}</p>
      }
    },
    {
      title: 'Monto',
      dataIndex: 'invoice_details',
      key: 'ammount',
      render: (text, record) => {
        return <p className="mb-0">$ {formatAmmount(record.invoice_details.ammount)}</p>
      }
    },
    {
      title: 'Empresa',
      dataIndex: 'company_name',
      key: 'company_name'
    },
    {
      title: 'Método de pago',
      dataIndex: 'payment_method',
      key: 'payment_method',
      render: (text, record) => {
        return <p className="mb-0">{record.payment_method.state}</p>
      }
    },
    {
      title: '',
      dataIndex: '',
      key: 'check_invoice',
      render: (text, record) => {
        return <Button onClick={() => checkInvoice(record.id)} type="primary" size="small">Ver factura</Button>
      }
    },
    {
      title: '',
      dataIndex: '',
      key: 'delete_invoice',
      render: (text, record) => {
        return <Button onClick={() => deleteInvoice(record.id)} type="primary" size="small">Eliminar</Button>
      }
    }
  ];

  const checkInvoice = async (id) => {
    /* here goes the external link to check invoice */
    window.open('www.cmt.cl');
  };

  const deleteInvoice = async (id) => {
    try {
      setLoading(true);
      const response = await specialServiceInvoiceService.deleteInvoice({ id });
      if (response.status === 'OK') {
        setRefresh(Math.random().toString(36));
        message.success({
          content: 'Factura eliminada exitosamente.',
          style: {
            marginTop: '40vh'
          }
        });
        setLoading(false);
      } else {
        message.error({
          content: 'Ocurrió un error. Inténtalo de nuevo,',
          style: {
            marginTop: '40vh'
          }
        });
        setLoading(false);
      }
    } catch (err) {
      console.error(err);
      message.error('Error interno.', err);
      setLoading(false);
    }
  };

  const formatDate = (date) => {
    return moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY');
  };

  const formatAmmount = (ammount) => {
    return new Number(ammount).toLocaleString('es-CL');
  };

  return (
    <Row justify="center">
      <Col lg={24} xs={24} className="mt-30">
        <Row justify="center">
          <Col lg={24} xs={24}>
            <h1>Otros servicios</h1>
          </Col>
          <Col lg={24} xs={24}>
            <Row justify="space-between">
              <Col lg={8} xs={24}>
                <Form
                  form={form}
                  onFinish={onFinish}
                  size="large"
                  style={{ width: '100%' }}
                >
                  <Row gutter={15}>
                    <Col lg={17}>
                      <Form.Item name="search">
                        <Input placeholder="Buscar por reserva o factura" />
                      </Form.Item>
                    </Col>
                    <Col lg={7}>
                      <Button htmlType="submit" type="primary" block>Buscar</Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
              <Col lg={3} xs={24}>
                <Link to="/otros-servicios/facturar">
                  <Button type="primary" size="large" block>Facturar</Button>
                </Link>
              </Col>
            </Row>
          </Col>
          <Col lg={24} className="mt-20">
            {
              loading ?
                <Spin style={{ display: 'flex', justifyContent: 'center' }} />
                :
                invoices &&
                <Table columns={columns} dataSource={invoices} pagination={false} />
            }
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SpecialServiceInvoicePage;