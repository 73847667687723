import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, Button, Spin, Radio } from 'antd';
import { generalInvoiceService } from '../../services/generalInvoice';
import AssociateInvoice from './AssociateInvoice';
import ReservationDetail from './ReservationDetail';
import CompanyDetail from './CompanyDetail';
import InvoiceDetail from './InvoiceDetail';
import { formatAmountByCurrency, formatDate, setTotalAmount } from '../../helpers';


const ReservationsToBillWithItems = ({ filters, refreshData, id }) => {
   const [loading, setLoading] = useState(false);
   const [reservations, setReservations] = useState();
   const [isAssociateInvoiceModalVisible, setIsAssociateInvoiceModalVisible] = useState(false);
   const [reservationData, setReservationData] = useState();
   const [currentPage, setCurrentPage] = useState(1);

   const fetchReservations = async (params) => {
      setLoading(true);
      const response = await generalInvoiceService.reservationsToBillWithItems(params);
      setReservations(response.data);
      setLoading(false);
   };

   useEffect(() => {
      const extra = { page: 1 }
      fetchReservations({ ...filters, ...extra });
   }, [filters, id]);

   const showAssociateInvoiceModal = (reservationId, reservationNumber, companyName) => {
      setReservationData({ reservationId, reservationNumber, companyName });
      !isAssociateInvoiceModalVisible ? setIsAssociateInvoiceModalVisible(true) : setIsAssociateInvoiceModalVisible(false);
   };

   const closeAssociateInvoiceModal = () => {
      setIsAssociateInvoiceModalVisible(false);
   };

   const loadMore = (e) => {
      const direction = e.target.value
      const _currentPage = direction == 'next' ? currentPage + 1 : currentPage - 1
      const extra = { page: _currentPage }
      fetchReservations({ ...filters, ...extra })
      setCurrentPage(_currentPage)
   }

   const setTotal = (record, total) => {
      setTotalAmount(record, total, reservations, setReservations);
   }

   const columns = [
      {
         title: 'N° de reserva',
         dataIndex: 'number',
         key: 'number',
         render: (text, record) => {
            return <CompanyDetail text={text} record={record} />
         }
      },
      {
         title: 'Fecha',
         dataIndex: 'reservation_date',
         key: 'reservation_date',
         render: (text) => {
            return <p className="mb-0">{formatDate(text)}</p>
         }
      },
      {
         title: 'Monto',
         dataIndex: 'amount_total',
         key: 'amount_total',
         render: (text, record) => {
            return <p className="mb-0">
               {text && formatAmountByCurrency({
                  countryCode: record.currency.locate_code,
                  currencyCode: record.currency.code,
                  amount: text
               })}
            </p>
         }
      },
      {
         title: 'Detalle (prestación/cantidad)',
         dataIndex: 'detail',
         key: 'detail',
         render: (text, record) => {
            return <ReservationDetail record={record} setTotal={setTotal} invoiced={false} />
         }
      },
      {
         title: 'Método de pago',
         dataIndex: 'payment_method',
         key: 'payment_method',
      },
      {
         title: 'Facturas asociadas',
         dataIndex: 'invoice_details',
         key: 'invoice_details',
         render: (text, record) => {
            return <InvoiceDetail text={text} record={record} />
         }
      },
      {
         title: '',
         dataIndex: '',
         key: 'generate_fc',
         render: (text, record) => {
            const query = new URLSearchParams({
               reservationId: record.id,
               companyId: record.company.id,
               countryId: record.currency.country_id,
               type: 'reservation',
            }).toString();
            return (
               <Link to={{
                  pathname: '/invoices/generator/reservation/detail',
                  search: `?${query}`
               }}>
                  <Button type="primary" size="small">Generar fc</Button>
               </Link>
            )
         }
      },
      {
         title: '',
         dataIndex: '',
         key: 'associate_fc',
         render: (text, record) => {
            return <Button
               onClick={() => showAssociateInvoiceModal(record.id, record.number, record.company.business_name)}
               size="small"
            >
               Asociar fc
            </Button>
         }
      }
   ];

   return (
      <React.Fragment>
         {
            loading ?
               <Spin style={{ display: 'flex', justifyContent: 'center' }} />
               :
               reservations &&
               <React.Fragment>
                  <Table columns={columns} dataSource={reservations} pagination={false} bordered={false} />

                  <Radio.Group value={'large'} onChange={loadMore} className="mb-20">
                     <Radio.Button value="prev" disabled={currentPage == 1}>Anterior</Radio.Button>
                     <Radio.Button value="next" disabled={!reservations || !reservations.length}>Siguiente</Radio.Button>
                  </Radio.Group>

                  <AssociateInvoice
                     isModalVisible={isAssociateInvoiceModalVisible}
                     refreshData={refreshData}
                     closeModal={closeAssociateInvoiceModal}
                     reservationData={reservationData}
                  />
               </React.Fragment>
         }
      </React.Fragment>
   );
};

export default ReservationsToBillWithItems;