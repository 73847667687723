import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { Layout, Card, Tabs, Row, Col, Button, Spin } from 'antd';
import billingInvoice from '../services/billingService';
import MonthSelector from './components/MonthSelector';
import Summary from './components/Summary';
import AgreementList from './components/AgreementList';
import moment from 'moment';

const { Content } = Layout;
const { TabPane } = Tabs;

const ConsolidatorPage = () => {
   const cookies = new Cookies();
   const roles = cookies.get('roles');
   const history = useHistory();
   const { month, year } = useParams();
   const date = moment().set({
      year: year,
      month: parseInt(month) - 1
   });

   const [isOpen, setIsOpen] = useState();
   const [isOpenning, setIsOpenning] = useState(false);
   const [loading, setLoading] = useState(false);
   const [id, setId] = useState();


   useEffect(() => {
      fetchCheck();
   }, [year, month, id])

   const fetchCheck = () => {
      setId(moment().unix());

      setLoading(true);
      billingInvoice
         .fetchCheck({
         month: date.month() + 1,
         year: date.year()
         })
         .then(response => {
         if (response) {
            setIsOpen(parseInt(response[0].count) > 0)
         } else {
            setIsOpen(false)
         }
         })
         .finally(() => {
         setLoading(false);
         })
   }

   const openMonth = async () => {
      try {
         setIsOpenning(true);
         await billingInvoice.openMonth({
         month: month,
         year: year
         })
         setId(moment().unix());
         setIsOpenning(false);
      } catch (err) {
         console.log(err);
         setId(moment().unix());
         setIsOpenning(false);
      }
   }

   const beforeMonth = () => {
      const currentDate = date.startOf('month').format('YYYY-MM-DD');
      const prevDate = moment(currentDate).subtract(1, 'month');
      history.push(`/consolidator/${prevDate.year()}/${prevDate.month() + 1}`);
   }

   const nextMonth = () => {
      const currentDate = date.startOf('month').format('YYYY-MM-DD');
      const prevDate = moment(currentDate).add(1, 'month');
      history.push(`/consolidator/${prevDate.year()}/${prevDate.month() + 1}`);
   }

   if (isOpen == null || loading) {
      return (
         <Row align="middle" justify="center" style={{ height: '100vh' }}>
            <Col>
               <Spin />
            </Col>
         </Row>
      )
   }

   if (isOpenning) {
      return (
         <Row justify="center"
         align="middle"
         className="open-month-action">
            <Col lg={12}>
               <Card>
                  <div className="loading">
                     <div>
                        <Spin />
                     </div>
                     Estamos cargando la información, por favor espere.
                     <span>
                        este proceso puede tardar algunos minutos.
                     </span>
                  </div>
               </Card>
            </Col>
         </Row>
      )
   }

   return (
      <Content className="mt-30">
         <h1>Consolidador de convenios</h1>
         <MonthSelector
            date={date}
            beforeMonth={beforeMonth}
            nextMonth={nextMonth}
         />


         {
         isOpen ? <React.Fragment>
            <Summary date={date} id={id} />
            <Tabs className="mt-40">
               <TabPane tab="Sin procesar" key="1">
                  <AgreementList date={date} state="not_processing" setId={setId} id={id} />
               </TabPane>
               <TabPane tab="Esperando O.C." key="2">
                  <AgreementList date={date} state="waiting_oc" setId={setId} id={id} />
               </TabPane>
               <TabPane tab="Esperando autorizacion" key="3">
                  <AgreementList date={date} state="waiting_authorization" setId={setId} id={id} />
               </TabPane>
               <TabPane tab="Listo para facturar" key="4">
                  <AgreementList date={date} state="ready_to_bill" setId={setId} id={id} />
               </TabPane>
               <TabPane tab="Facturadas" key="5">
                  <AgreementList date={date} state="invoiced" setId={setId} id={id} />
               </TabPane>
            </Tabs>
         </React.Fragment> :
            <Row justify="center"
               align="middle"
               className="open-month-action">
               <Col lg={12}>
                  <Card>
                     <div>
                        El mes aún no se encuentra abierto.
                     </div>
                     <Button type="primary"
                        size="large"
                        disabled={roles && !roles.includes('contabilidad')}
                        onClick={openMonth}>
                        Abrir Mes
                     </Button>
                  </Card>
               </Col>
            </Row>
         }
      </Content>
   )
};

export default ConsolidatorPage;