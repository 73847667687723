import React, { useState, useEffect } from 'react';
import { Popover, Row, Col, Tag } from 'antd';
import { generalInvoiceService } from '../../services/generalInvoice';
import * as _ from 'lodash';

const ReservationDetail = ({ record, setTotal, invoiced }) => {
  const [services, setServices] = useState();

  const fetchDetail = async () => {
    const response = await generalInvoiceService.servicesByReservation({ reservationId: record.id, invoiced });
    setServices(response);

    setTotal(record, _.sumBy(response, item => {
      return item.service_amount * parseInt(item.count)
    }));
  }

  useEffect(() => {
    fetchDetail();
  }, [])

  return (
    <Popover
      placement="topLeft"
      trigger="click"
      content={
        <Row justify="center" style={{ width: 400 }}>
          <Col lg={24} xs={24}>
            <Row>
              <Col lg={12} xs={12}>
                <h3>Prestación</h3>
              </Col>
              <Col lg={12} xs={12}>
                <h3 className="text-center">Cantidad</h3>
              </Col>
            </Row>
          </Col>
          <Col lg={24} xs={24}>
            <Row>
              {
                services && services.map(service => {
                  return (
                    <React.Fragment key={service.id}>
                      <Col lg={12} xs={12}>
                        <Tag>{service.service_name}</Tag>
                      </Col>
                      <Col lg={12} xs={12}>
                        <p className="text-center">{service.count}</p>
                      </Col>
                    </React.Fragment>
                  )
                })
              }
            </Row>
          </Col>
        </Row>
      }
    >
      <p className="mb-0" style={{ cursor: 'pointer' }}>Ver detalle</p>
    </Popover>
  )
}

export default ReservationDetail;