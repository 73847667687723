import { useState, useEffect } from 'react';
import { Row, Col, Tabs, Button, Form, DatePicker, Input, Select } from 'antd';
import ReservationsToBill from './components/ReservationsToBill';
import BilledReservations from './components/BilledReservations';
import ReservationsToBillWithItems from './components/ReservationsToBillWithItems';
import { generalInvoiceService } from '../services/generalInvoice';
import locale from 'antd/es/date-picker/locale/es_ES';
import moment from 'moment';

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const { format } = require('rut.js');

const GeneralInvoicePage = () => {
   const [filters, setFilters] = useState({
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD')
   });
   const [paymentMethods, setPaymentMethods] = useState();
   const [branchOffices, setBranchOffices] = useState();
   const [id, setId] = useState(Math.random());
   const [form] = Form.useForm();

   const fetchPaymentMethods = async () => {
      const response = await generalInvoiceService.paymentMethods();
      setPaymentMethods(response);
   };

   const fetchBranchOfficesByAdmin = async () => {
      const _filters = filters;
      const response = await generalInvoiceService.branchOfficesByAdmin();
      setBranchOffices(response);
      _filters['branchOffice'] = response[0]['id'];
      setFilters(_filters);
      form.setFieldsValue({
         branchOffice: response[0]['id']
      })
   }

   useEffect(() => {
      fetchBranchOfficesByAdmin();
      fetchPaymentMethods();
      form.setFieldsValue({
         date: [
            moment(filters.startDate, 'YYYY-MM-DD'),
            moment(filters.endDate, 'YYYY-MM-DD'),
         ]
      })
   }, []);

   const refreshData = () => {
      setId(Math.random())
   }

   const onFinish = values => {
      const params = {
         startDate: values.date && values.date[0].format('YYYY-MM-DD'),
         endDate: values.date && values.date[1].format('YYYY-MM-DD'),
         reservationNumber: values.reservationNumber,
         paymentMethod: values.paymentMethod,
         branchOffice: values.branchOffice
      };

      if (values.document) {
         params['document'] = values.document;
      }

      const removeUndefinedValues = Object.fromEntries(
         Object.entries(params).filter(([_, values]) => values !== undefined)
      );

      setFilters(removeUndefinedValues);
   };

   const formatRutInput = event => {
      const formatRut = format(event.target.value);
      form.setFieldsValue({ rut: formatRut });
   };

   const download = () => {
      const _filters = {};

      if (filters.startDate) {
         _filters['by_date_start'] = filters.startDate;
      }

      if (filters.endDate) {
         _filters['by_date_stop'] = filters.endDate;
      }

      if (filters.branchOffice) {
         _filters['branch_office_id'] = filters.branchOffice;
      }

      if (filters.paymentMethod) {
         _filters['by_payment_method'] = filters.paymentMethod;
      }

      const data = encodeURIComponent(JSON.stringify(_filters));
      window.open(`https://intranet.cmtsalud.cl/billings/new?filterrific=${data}`);
   }

   return (
      <Row justify="center">
         <Col lg={24} xs={24} className="mt-30">
            <Row justify="start">
               <Col lg={24} xs={24}>
                  <h1>Facturador general</h1>
               </Col>
               <Col lg={24} xs={24}>
                  <Form
                     form={form}
                     layout="vertical"
                     onFinish={onFinish}
                     style={{ width: '100%' }}
                     size="large"
                  >
                     <Row justify="start" align="bottom" gutter={15}>
                        <Col lg={5} xs={24}>
                           <Form.Item label="Fecha" name="date" className="mb-0">
                              <RangePicker locale={locale} placeholder={['Desde', 'Hasta']} picker="date" />
                           </Form.Item>
                        </Col>
                        <Col lg={3} xs={24}>
                           <Form.Item name="reservationNumber" className="mb-0">
                              <Input placeholder="N° de reserva" />
                           </Form.Item>
                        </Col>
                        <Col lg={4} xs={24}>
                           <Form.Item name="document" className="mb-0" onChange={formatRutInput}>
                              <Input placeholder="N° de Documento empresa" />
                           </Form.Item>
                        </Col>
                        <Col lg={4} xs={24}>
                           <Form.Item name="branchOffice" className="mb-0">
                              <Select placeholder="Sucursal">
                                 {
                                    branchOffices && branchOffices.map(branchOffice => {
                                       return <Select.Option key={branchOffice.id} value={branchOffice.id}>{branchOffice.name}</Select.Option>
                                    })
                                 }
                              </Select>
                           </Form.Item>
                        </Col>
                        <Col lg={4} xs={24}>
                           <Form.Item name="paymentMethod" className="mb-0">
                              <Select placeholder="Método de pago">
                                 {
                                    paymentMethods && paymentMethods.map(payment => {
                                       return <Select.Option key={payment.id} value={payment.id}>{payment.name}</Select.Option>
                                    })
                                 }
                              </Select>
                           </Form.Item>
                        </Col>
                        <Col lg={4} xs={24}>
                           <Button htmlType="submit" type="primary" block>Buscar</Button>
                        </Col>
                     </Row>
                  </Form>
               </Col>
            </Row>
            <Row justify="center" className="mt-60">
               <Col lg={24} xs={24}>
                  {
                     filters['branchOffice'] &&
                     <Tabs defaultActiveKey="1">
                        <TabPane tab="Reservas por facturar" key="1">
                           <ReservationsToBill filters={filters} refreshData={refreshData} id={id} />
                        </TabPane>
                        <TabPane tab="Reservas facturadas" key="2">
                           <BilledReservations filters={filters} />
                        </TabPane>
                        <TabPane tab="Reservas por facturar con nuevos ítems" key="3">
                           <ReservationsToBillWithItems filters={filters} refreshData={refreshData} id={id} />
                        </TabPane>
                     </Tabs>
                  }

               </Col>
            </Row>
            <Row justify="end" className="mb-40">
               <Col>
                  <Button type="primary" onClick={download}>Descargar Reporte</Button>
               </Col>
            </Row>
         </Col>
      </Row>
   );
};

export default GeneralInvoicePage;