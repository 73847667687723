import React, { useEffect, useState } from "react";
import {
  Input,
  Row,
  Col,
  Button,
  Modal,
  Upload,
  Table,
  Form,
  Radio,
  Checkbox,
  Select,
  Tag,
  message,
} from "antd";
import moment from "moment-timezone";
import _ from "lodash";
import { useLocation } from 'react-router-dom';
import { InboxOutlined, FileExcelOutlined } from '@ant-design/icons';
import xlsx from 'json-as-xlsx';
import Filters from './Filters';
import billingInvoice from '../../services/billingService';
import { formatAmountByCurrency, formatAmount } from '../../helpers';

const { Dragger } = Upload;
const states = ['not_processing', 'waiting_authorization', 'waiting_oc'];

const PrestationTable = ({
  billing,
  purchaseOrders,
  agreementType,
  rejectReasonOptions,
  prestations,
  setId,
  uploadOC,
  associateOC,
  loading,
  isPrivate
}) => {
  const [isObservationModal, setIsObservationModal] = useState(false);
  const [authorizationVisible, setAuthorizationVisible] = useState(false);
  const [authorizationLoading, setAuthorizationLoading] = useState(false);
  const [isUploadOCModal, setIsUploadOCModal] = useState(false);
  const [observationReason, setObservationReason] = useState();
  const [observationMessage, setObservationMessage] = useState();
  const [filters, setFilters] = useState({});
  const [attachOCType, setAttachOCType] = useState();
  const [selected, setSelected] = useState();
  const [email, setEmail] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [checkAll, setCheckAll] = useState(false);
  const [formOCLoading, setFormOCLoading] = useState(false);

  const [formOC] = Form.useForm();
  const [formAssociateOC] = Form.useForm();
  const [formAuthorization] = Form.useForm();
  const location = useLocation();

  useEffect(() => {
    setSelected(prestations);
    const query = new URLSearchParams(location.search);
    setEmail(query.get('e'));
  }, []);

  useEffect(() => {
    let total = 0;
    selectedRowKeys.forEach((key) => {
      const result = selected.find(item => item.id === key);
      if (result) {
        total += parseFloat(result.amount, 10);
      }
    });
    setSelectedAmount(total);
  }, [selectedRowKeys.length])

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const onChangeObservation = (event) => {
    setObservationReason(event.target.value);
  }

  const onChangeObservationMessage = (event) => {
    setObservationMessage(event.target.value);
  }

  const showObservationModal = () => {
    setIsObservationModal(true);
  }

  const showUploadOCModal = () => {
    setIsUploadOCModal(true);
  }

  const authorizationCancel = () => {
    setAuthorizationVisible(false)
  }

  const showAuthorizationConfirm = () => {
    setAuthorizationVisible(true);
  }

  const handleObservationOk = async () => {
    const ids = selectedRowKeys;
    const item = {
      ids,
      observationReason
    }

    if (observationMessage) {
      item['observationMessage'] = observationMessage;
    }

    await billingInvoice.addObservation(item);
    setSelectedRowKeys([]);
    setIsObservationModal(false);
    setId(moment().unix());
  }

  const handleObservationCancel = () => {
    setIsObservationModal(false);
  };

  const handleAuthorize = (values) => {
    setAuthorizationLoading(true);
    const billingId = billing.id;
    billingInvoice.authorize({ billingId, email })
      .then((response) => {
        message.success('Autorización enviada exitosamente');
        setId(moment().unix());
      }).finally(() => {
        setAuthorizationLoading(false);
        setAuthorizationVisible(false);
      }).catch(err => {
        message.error('Ups tenemos un error para procesar la solicitud')
      });
  }

  const checkOnChange = (checked, record) => {
    if (checked) {
      setSelectedRowKeys([...selectedRowKeys, record.id])
    } else {
      setSelectedRowKeys(selectedRowKeys.filter(key => {
        return key !== record.id;
      }));
    }
  }

  const checkOn = (id) => {
    return selectedRowKeys.includes(id);
  }

  const setFilter = (type, value) => {
    setSelected([]);
    let tempFilters = filters;
    let tempData = prestations;
    tempFilters[type] = value;
    tempFilters = _.pickBy(filters, _.identity);
    if (tempFilters && (value && value.length)) {

      const customFilter = (item) => {
        for (let type of Object.keys(tempFilters)) {
          return tempFilters[type].includes(item[type])
        }
      }

      setFilters(tempFilters);
      setSelected(_.filter(tempData, customFilter));
      setSelectedRowKeys(_.map(_.filter(tempData, customFilter), 'id'));
    } else {
      setSelectedRowKeys([]);
      setSelected(tempData);
    }
  }

  const columns = [
    {
      width: 100,
      title: 'Reserva y Sucursal',
      dataIndex: 'number',
      key: 'number',
      render: (text, record) => {
        return (
          <React.Fragment>
            <div className="text-bold">{text}</div>
            <div>
              <Tag>{record.branch_name}</Tag>
            </div>
          </React.Fragment>
        )
      }
    },
    {
      width: 60,
      title: 'Fecha',
      dataIndex: 'admission_date',
      key: 'admission_date',
      render: (text) => {
        return moment(text, 'YYYY-MM-DD_HH-mm-ssZ').tz('Europe/Dublin').format('YYYY-MM-DD')
      }
    },
    {
      title: 'Paciente',
      dataIndex: 'patient_name',
      key: 'patient_name',
      render: (text, record) => {
        return (
          <React.Fragment>
            <div>{text}</div>
            <div>{record.patient_document}</div>
          </React.Fragment>
        )
      }
    },
    {
      title: 'Prestación',
      dataIndex: 'prestation_name',
      key: 'prestation_name',
      render: (text, record) => {
        return (
          <React.Fragment>
            <div>{text}</div>
          </React.Fragment>
        )
      }
    },
    {
      title: 'Solicitante',
      dataIndex: 'user_name',
      key: 'user_name',
      render: (text, record) => {
        return (
          <React.Fragment>
            <div>{text}</div>
            {
              record.cost_center_name && (
                <div>
                  {`C.C `}
                  <Tag>
                    {record.cost_center_name}
                  </Tag>
                </div>
              )
            }
          </React.Fragment>
        )
      }
    },
    {
      title: 'Monto',
      dataIndex: 'amount',
      key: 'amount',
      render: (text, record) => {
        return (
          <div className="text-bold">
            {
              record.discount_amount > 0 && <div style={{ fontSize: 10, textAlign: 'right', color: 'grey' }}>
                {formatAmountByCurrency({
                  countryCode: record.currency.locate_code,
                  currencyCode: record.currency.code,
                  amount: record.sub_amount
                })}
              </div>
            }
            <div style={{ textAlign: 'right' }}>
              {formatAmountByCurrency({
                countryCode: record.currency.locate_code,
                currencyCode: record.currency.code,
                amount: text
              })}
            </div>
          </div>
        );
      }
    },
    {
      width: 20,
      title: () => {
        return (
          <Checkbox
            disabled={billing.state == 'ready_to_bill' || agreementType == 'Autorización'}
            checked={checkAll}
            onChange={(event) => {
              if (event.target.checked) {
                setSelectedRowKeys(_.map(selected, 'id'));
              } else {
                setSelectedRowKeys([]);
              }
              setCheckAll(event.target.checked);
            }}
          />
        )
      },
      dataIndex: 'check',
      key: 'check',
      render: (text, record) => {
        return (
          <Checkbox
            disabled={billing.state == 'ready_to_bill' || agreementType == 'Autorización'}
            checked={checkOn(record.id)}
            onChange={(event) => {
              checkOnChange(event.target.checked, record)
            }}
          />
        )
      }
    },
  ]

  const options = [
    {
      label: 'Adjuntar nueva OC', value: 'Adjuntar nueva OC'
    },
    {
      label: 'Asociar OC existente', value: 'Asociar OC existente'
    },
  ];

  const uploadOCChange = (event) => {
    setAttachOCType(event.target.value);
  }

  const submitOCFile = async (values) => {
    setFormOCLoading(true);
    values['file'] = JSON.parse(values['file']);
    await uploadOC(selectedRowKeys, values);
    setId(moment().unix());
    setFormOCLoading(false);
  }

  const submitOCAssociation = async (values) => {
    await associateOC(selectedRowKeys, values.id);
    setId(moment().unix());
  }

  const canAuthorize = () => {
    return agreementType == 'Autorización'
      && states.includes(billing.state);
  }

  const canAttachOC = () => {
    return agreementType == 'Orden de Compra' &&
      states.includes(billing.state);
  }

  const canAttachObservation = () => {
    return false;
    // return states.includes(billing.state);
  }

  const downloadXlsx = () => {
    let items = [
      {
        sheet: "Prestaciones",
        columns: [
          { label: "N° Reserva", value: "number" },
          { label: "Empresa", value: "company_name" },
          { label: "Centro de Costo", value: "cost_center_name" },
          { label: "N° de OC", value: "oc_number" },
          { label: "Fecha", value: "admission_date" },
          { label: "Prestación", value: "prestation_name" },
          { label: "Paciente", value: "patient_name" },
          { label: "Documento Paciente", value: "patient_document" },
          { label: "Sucursal", value: "branch_name" },
          { label: "Monto", value: (row) => formatAmount(row.amount) },
        ],
        content: prestations,
      },
    ]

    let settings = {
      fileName: "Prestaciones",
      extraLength: 3,
      writeOptions: {},
    }

    xlsx(items, settings)
  }

  return (
    <React.Fragment>
      {
        prestations && selected && (
          <React.Fragment>
            <Row className="mt-20">
              <Col lg={24}>
                <Filters
                  setFilter={setFilter}
                  prestations={prestations} />
              </Col>
              <Col lg={24}>
                <Table dataSource={selected}
                  columns={columns}
                  loading={loading}
                  pagination={false}
                  className="prestation-table"
                />
              </Col>
            </Row>

            <Row justify="end" gutter={10}>
              <Col>
                {
                  canAuthorize() && (
                    <React.Fragment>
                      <Button type="primary" size="large" onClick={showAuthorizationConfirm}>
                        Autorizar
                      </Button>

                      <Modal
                        title={
                          <h3 className="text-bold">
                            Confirmación de autorización
                          </h3>
                        }
                        visible={authorizationVisible}
                        footer={null}
                        onCancel={authorizationCancel}
                      >
                        <Form onFinish={handleAuthorize} form={formAuthorization} layout="vertical">
                          <div className="mb-20">
                            Se autorizará la emisión de la factura correspondiente con el correo <strong>{email}</strong>
                          </div>
                          <Form.Item label="">
                            <Button
                              htmlType="submit"
                              type="primary"
                              style={{ float: 'right' }}
                              size="large"
                              loading={authorizationLoading}
                            >
                              Autorizar
                            </Button>
                          </Form.Item>
                        </Form>
                      </Modal>
                    </React.Fragment>
                  )
                }

                <Button
                  onClick={downloadXlsx}
                  size="large"
                  className="mr-10"
                  style={{ borderColor: "#1D6F42", color: "#1D6F42" }}
                >
                  <FileExcelOutlined style={{ color: '#1D6F42' }} />
                  Descargar Excel
                </Button>

                {
                  canAttachOC() && <React.Fragment>
                    <Button
                      disabled={selectedRowKeys.length == 0}
                      type="primary"
                      onClick={showUploadOCModal}
                      size="large">
                      Adjuntar OC
                    </Button>
                    <Modal
                      width={900}
                      title={
                        <h3 className="text-bold">
                          Asociar OC
                        </h3>
                      }
                      onCancel={() => {
                        setIsUploadOCModal(false);
                      }}
                      visible={isUploadOCModal}
                      footer={null}
                    >
                      <Row>
                        <Col lg={12}>
                          <Radio.Group
                            options={options}
                            onChange={uploadOCChange}
                          />
                        </Col>
                      </Row>

                      {
                        attachOCType == 'Adjuntar nueva OC' && (
                          <Row>
                            <Col lg={24}>
                              <Form onFinish={submitOCFile} form={formOC} layout="vertical">
                                <Row>
                                  <Col lg={8}>
                                    <Form.Item label="Número OC" name="number" rules={[{ required: true }]}>
                                      <Input />
                                    </Form.Item>
                                  </Col>
                                  <Col lg={24}>
                                    <Form.Item name="file" rules={[{ required: true }]}>
                                      <Input type="hidden" />
                                      <Dragger
                                        name="file"
                                        customRequest={async (info) => {
                                          const { onSuccess } = info;
                                          onSuccess("Ok");
                                        }}
                                        onChange={async (info) => {
                                          getBase64(info.file.originFileObj, image64 => {
                                            formOC.setFieldsValue({
                                              file: JSON.stringify({
                                                name: info.file.name,
                                                type: info.file.type,
                                                data: image64
                                              })
                                            })
                                          });
                                        }}
                                      >
                                        <p className="ant-upload-drag-icon">
                                          <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text">
                                          Click o arrastra el archivo de OC aquí
                                        </p>
                                      </Dragger>
                                    </Form.Item>
                                  </Col>
                                </Row>

                                <Form.Item className="mt-20">
                                  <Button loading={formOCLoading} htmlType="submit" type="primary" size="large" style={{ float: 'right' }}>
                                    Guardar
                                  </Button>
                                </Form.Item>
                              </Form>
                            </Col>
                          </Row>
                        )
                      }
                      {
                        attachOCType == 'Asociar OC existente' && (
                          <Form onFinish={submitOCAssociation} form={formAssociateOC}>
                            <Form.Item name="id">
                              <Select style={{ width: 350 }} placeholder="Seleccionar Orden de Compra">
                                {Object.keys(purchaseOrders).map((key) => {
                                  const partition = key.split(':');
                                  return (
                                    <Select.Option value={partition[0]} style={{ width: 350 }}>
                                      {partition[1]}
                                    </Select.Option>
                                  )
                                })}
                              </Select>
                            </Form.Item>

                            <Form.Item>
                              <Button htmlType="submit" type="primary" size="large">
                                Guardar
                              </Button>
                            </Form.Item>
                          </Form>
                        )
                      }
                    </Modal>
                  </React.Fragment>
                }
              </Col>

              {
                canAttachObservation() && (
                  <Col>
                    <Button
                      disabled={selectedRowKeys.length == 0}
                      type="primary"
                      onClick={showObservationModal}
                      size="large">
                      Observar
                    </Button>
                    <Modal
                      width={900}
                      title={
                        <h3 className="text-bold">
                          Seleccione el motivo de observación para {selectedRowKeys.length} prestaciones
                        </h3>
                      }
                      visible={isObservationModal}
                      onOk={handleObservationOk}
                      onCancel={handleObservationCancel}>
                      <Radio.Group
                        options={rejectReasonOptions}
                        onChange={onChangeObservation}
                      />

                      {
                        observationReason == 'other_reason' && (
                          <Input
                            style={{ width: 400 }}
                            placeholder="motivo"
                            onChange={onChangeObservationMessage}
                          />
                        )
                      }
                    </Modal>
                  </Col>
                )
              }

            </Row>
            {
              selectedAmount > 0 && (
                <div style={{
                  position: 'fixed',
                  bottom: 0,
                  right: 50,
                  fontSize: 18,
                  boxShadow: '1px 1px 8px -2px',
                  padding: '5px 10px',
                  background: 'white',
                  fontWeight: 'bold'
                }}>

                  {formatAmountByCurrency({
                    countryCode: billing.currency.locate_code,
                    currencyCode: billing.currency.code,
                    amount: selectedAmount
                  })}

                </div>
              )
            }
          </React.Fragment>
        )
      }
    </React.Fragment >
  )
}

export default PrestationTable;