import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Spin, Row, Col, Button, Popover, List, Modal } from 'antd';
import moment from 'moment';
import billingService from '../services/billingService';
import invoiceService from '../services/invoiceService';
import PrestationTabs from './components/PrestationTabs';
import SummaryCard from './components/SummaryCard';
import HeaderCard from './components/HeaderCard';
import AssociateInvoice from './components/AssociateInvoice';
import _ from "lodash";

const PrestationListPage = (props) => {
  const unix = moment().unix();
  const [id, setId] = useState(unix);
  const [billing, setBilling] = useState();
  const [rawData, setRawData] = useState();
  const [prestations, setPrestations] = useState();
  const [withOrders, setWithOrders] = useState();
  const [invoices, setInvoices] = useState();
  const [withObservations, setWithObservations] = useState();
  const [summary, setSummary] = useState();
  const { billingCode, companyCode, month, year, companyId } = useParams();
  const [loading, setloading] = useState(false);
  const [purchaseOrders, setPurchaseOrders] = useState();
  const [loadingDownload, setLoadingDownload] = useState(false);

  const getBillingCompanyParams = () => {
    if (props.private) {
      const billingId = billing['id'];
      return { companyId, month, year, billingId };
    }

    return { companyCode, billingCode };
  }

  const getBillingParams = () => {
    if (props.private) {
      return { companyId, month, year };
    }

    return { companyCode, billingCode };
  }

  const fetchBilling = async () => {
    const params = getBillingParams();
    const response = await billingService.fetchBillingById(params);
    setBilling(response);
  }

  const fetchPrestations = async () => {
    setloading(true);
    setPrestations();
    const params = getBillingParams();
    const response = await billingService.fetchPrestations(params);
    setRawData(response);
    if (response && response.length > 0 && response[0].agreement_type == 'Orden de Compra') {
      const partition1 = _.partition(response, (item) => {
        return (item.document_file_name == null
          && item.observation_reason == null) || (
            item.document_file_name == null && item.observation_fixed == true
          );
      });

      const partition2 = _.partition(partition1[1], (item) => {
        return (item.document_file_name != null && item.observation_reason == null)
          || (item.document_file_name != null && item.observation_fixed == true);
      })

      setWithOrders(partition2[0])
      setPurchaseOrders(_.groupBy(partition2[0], (item) => {
        return `${item.purchase_order_id}:${item.oc_number}`;
      }))
      setWithObservations(partition2[1]);
      setPrestations(mapData(partition1[0]));
    } else {
      const partition3 = _.partition(response, (item) => {
        return (item.observation_reason == null
          || item.observation_fixed == true);
      })
      setPrestations(mapData(partition3[0]));
      setWithObservations(partition3[1]);
    }
    setSummary(_.groupBy(response, 'prestation_name'));
    setloading(false);
  }

  const mapData = (items) => {
    return items.map((prestation, index) => {
      prestation['key'] = prestation['id'];
      prestation['patient_name'] = prestation['patient_name'].toLowerCase()
      prestation['user_name'] = prestation['user_name'].toLowerCase()
      return prestation;
    })
  }

  const download = async () => {
    setLoadingDownload(true);
    const params = getBillingCompanyParams();
    const response = await billingService.downloadPreInvoice(params);
    setLoadingDownload(false);
    const cleanUrl = response.url.split('?')[0];
    window.location.href = cleanUrl;
  }

  const uploadOC = (ids, info) => {
    return new Promise((resolve, reject) => {
      const params = getBillingCompanyParams();

      billingService.uploadOC({
        ...params,
        ...info,
        ids,
      }).then((res) => {
        resolve(true);
      }).catch(() => {
        reject(false);
      });
    });
  }

  const associateOC = (ids, id, billingId) => {
    return new Promise((resolve, reject) => {
      const params = getBillingCompanyParams();
      billingService.uploadOC({
        billingId,
        ...params,
        id,
        ids
      }).then((res) => {
        resolve(true);
      }).catch(() => {
        reject(false);
      });
    });
  }

  const canCreateInvoice = () => {
    return (billing && billing.agreement_type == 'Factura'
      && billing.state != 'invoiced')
      || (billing && billing.agreement_type == 'Autorización'
        && billing.state != 'invoiced'
      )
  }

  const fetchInvoices = async () => {
    const response = await invoiceService.fetchInvoicesByBilling({ billingId: billing.id });
    setInvoices(response);
  }

  useEffect(() => {
    fetchBilling();
    fetchPrestations();
  }, [id]);

  useEffect(() => {
    if (billing && ['invoiced', 'invoiced_notified', 'ready_to_bill'].includes(billing.state)) {
      fetchInvoices();
    }
  }, [billing])

  return (

    <Spin spinning={loading}>
      {props.private && <div className="mt-60 mb-40">
        <Link to={`/consolidator/${year}/${month}/`}>Volver</Link>
      </div>}

      <div>
        <h1 className="text-center">
          Detalle de prestaciones prefacturación
        </h1>
      </div>

      <Row className="prestations">
        <Col lg={12}>
          <Row gutter={20}>
            <Col>
              <Button type="default"
                loading={loadingDownload}
                onClick={download}
                size="large">
                Descargar prefactura en Excel
              </Button>
            </Col>

            {
              invoices && invoices.length > 1 && (
                <Popover content={<List>
                  {invoices.map(invoice => {
                    return (
                      <List.Item>
                        <a href={invoice.bsale_invoice_url} target="_blank">Factura Nº {invoice.inv_number}</a>
                      </List.Item>
                    )
                  })}
                </List>} title="Facturas" trigger="click">
                  <Button size="large" type="default">Descargar Facturas</Button>
                </Popover>
              )
            }

            {
              invoices && invoices.length == 1 && (
                <Button size="large" type="default" onClick={() => {
                  window.open(invoices[0].bsale_invoice_url, "_blank");
                }}>
                  Descargar Factura Nº {invoices[0].inv_number}
                </Button>
              )
            }
          </Row>
        </Col>

        <Col lg={12}>
          {
            props.private && canCreateInvoice() && <div className="pull-right">
              <AssociateInvoice
                actionSize="large"
                billing={billing}
                month={month}
                setId={setId}
                year={year} />

              <Link to={{
                pathname: '/invoices/generator/agreement/detail',
                search: `?companyId=${companyId}&billingId=${billing.id}&countryId=${billing.currency.country_id}`
              }}>
                <Button size="large" type="primary">
                  Facturar
                </Button>
              </Link>
            </div>
          }
        </Col>


        {
          billing && prestations && summary && <React.Fragment>
            <Col lg={24} className="mt-40">
              <HeaderCard
                prestations={rawData}
                month={month}
                year={year} />
            </Col>
            <Col lg={24} className="mt-40">
              <SummaryCard summary={summary} billing={billing} />
            </Col>
            <Col lg={24} className="mt-40">
              <PrestationTabs
                month={month}
                year={year}
                isPrivate={props.private}
                purchaseOrders={purchaseOrders}
                prestations={prestations}
                withOrders={withOrders}
                withObservations={withObservations}
                setId={setId}
                uploadOC={uploadOC}
                associateOC={associateOC}
                agreementType={billing.agreement_type}
                companyId={companyId}
                billing={billing}
              />
            </Col>
          </React.Fragment>
        }
      </Row>
    </Spin >

  )
}

export default PrestationListPage;