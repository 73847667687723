import { useState, useEffect } from "react";
import { generalInvoiceService } from '../../services/generalInvoice';
import { Table, Popover, Button, Spin } from 'antd';
import { DeleteFilled } from '@ant-design/icons';
import { formatAmountByCurrency } from '../../helpers';

const states = {
  'unpaid': 'no pagado',
  'paid': 'pagado',
}

const InvoiceDetail = ({ record, text }) => {
  const [invoices, setInvoices] = useState();
  const [loading, setLoading] = useState();
  const fetchInvoices = async () => {
    setLoading(true)
    const response = await generalInvoiceService.invoicesByReservation({ reservationId: record.id });
    setInvoices(response);
    setLoading(false)
  }

  useEffect(() => {
    fetchInvoices();
  }, [])

  const removeInvoice = async (invoice) => {
    if (window.confirm(`Realmente deseas quitar esta factura en la reserva ${record.number}?`)) {
      await generalInvoiceService.disassociateInvoiceToReservation({ reservationId: record.id, invoiceId: invoice.id })
      fetchInvoices();
    }
  }

  return (
    <Popover
      placement="topLeft"
      trigger="click"
      content={
        <Spin spinning={loading}>
          <Table columns={[
            {
              title: 'Numero',
              dataIndex: 'inv_number',
              key: 'inv_number',
              render: (text, record) => {
                return <a target="_blank" href={record.url}>
                  Nº {text}
                </a>
              }
            },
            {
              title: 'Monto',
              dataIndex: 'amount_total',
              key: 'amount_total',
              render: (text, record) => {
                return formatAmountByCurrency({
                  countryCode: record.currency.locate_code,
                  currencyCode: record.currency.code,
                  amount: text
                })
              }
            },
            {
              title: 'Estado',
              dataIndex: 'state',
              key: 'state',
              render: (text) => {
                return states[text];
              }
            },
            {
              title: '',
              dataIndex: 'action',
              key: 'action',
              render: (text, record) => {
                return <Button onClick={() => removeInvoice(record)} type="link" icon={<DeleteFilled />} />
              }
            },
          ]} dataSource={invoices} pagination={false} />
        </Spin>
      }
    >
      <p className="mb-0" style={{ cursor: 'pointer' }}>Ver Facturas</p>
    </Popover>
  )
}

export default InvoiceDetail;