import { authHeader, handleResponse } from './helper';
import config from '../config';

const fetchCompanyInfo = (params) => {
   const requestOptions = {
      method: 'GET',
      headers: authHeader()
   };
   const url = new URL(`${config.apiUrl}/invoices/companyInfo`);
   Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
   return fetch(url, requestOptions).then(handleResponse);
}

const fetchServices = (params) => {
   const requestOptions = {
      method: 'GET',
      headers: authHeader()
   };
   const url = new URL(`${config.apiUrl}/invoices/prestations`);
   Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
   return fetch(url, requestOptions).then(handleResponse);
}

const fetchReferenceDocuments = (params) => {
   const requestOptions = {
      method: 'GET',
      headers: authHeader()
   };
   const url = new URL(`${config.apiUrl}/invoices/referenceDocuments`);
   Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
   return fetch(url, requestOptions).then(handleResponse);
}

const checkOCInvoiced = (params) => {
   const requestOptions = {
      method: 'GET',
      headers: authHeader()
   };
   const url = new URL(`${config.apiUrl}/invoices/checkOCInvoiced`);
   Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
   return fetch(url, requestOptions).then(handleResponse);
}

const createInvoice = (params) => {
   const requestOptions = {
      method: 'POST',
      headers: {
         'Content-Type': 'application/json',
         ...authHeader()
      },
      body: JSON.stringify(params)
   };
   const url = new URL(`${config.apiUrl}/invoices/create`);
   return fetch(url, requestOptions).then(handleResponse);
}

const fetchInvoicesByBilling = (params) => {
   const requestOptions = {
      method: 'GET',
      headers: authHeader()
   };
   const url = new URL(`${config.apiUrl}/invoices/byBilling`);
   Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
   return fetch(url, requestOptions).then(handleResponse);
}

const fetchExternalInvoice = (params) => {
   const requestOptions = {
      method: 'GET',
      headers: authHeader()
   };
   const url = new URL(`${config.apiUrl}/invoices/getExternalInvoice`);
   Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
   return fetch(url, requestOptions).then(handleResponse);
}

const fetchExternalPaymentMethods = (params) => {
   const requestOptions = {
      method: 'GET',
      headers: authHeader()
   };
   const url = new URL(`${config.apiUrl}/invoices/getExternalPayments`);
   Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
   return fetch(url, requestOptions).then(handleResponse);
}

const fetchExternalDynamicAttributes = (params) => {
   const requestOptions = {
      method: 'GET',
      headers: authHeader()
   };
   const url = new URL(`${config.apiUrl}/invoices/getExternalDynamicAttributes`);
   Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
   return fetch(url, requestOptions).then(handleResponse);
}

const associateInvoice = (params) => {
   const requestOptions = {
      method: 'POST',
      headers: {
         'Content-Type': 'application/json',
         ...authHeader()
      },
      body: JSON.stringify(params)
   };
   const url = new URL(`${config.apiUrl}/invoices/associateInvoice`);
   Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
   return fetch(url, requestOptions).then(handleResponse);
}

export default {
   fetchExternalDynamicAttributes,
   fetchExternalPaymentMethods,
   fetchInvoicesByBilling,
   checkOCInvoiced,
   fetchCompanyInfo,
   fetchServices,
   fetchReferenceDocuments,
   fetchExternalInvoice,
   createInvoice,
   associateInvoice
}