import React, { useState, useEffect } from "react";
import { Row, Col, Button, Table, Form, Input, Select, DatePicker, Spin, message, Modal, Radio } from 'antd';
import invoiceFinderService from "../services/invoiceFinderService";
import locale from 'antd/es/date-picker/locale/es_ES';
import { formatAmountByCurrency } from "../helpers";

const { RangePicker } = DatePicker;
const { TextArea } = Input;

const InvoiceFinder = () => {
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState();
  const [invoice, setInvoice] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refresh, setRefresh] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [form] = Form.useForm();

  const fetchInvoices = async (params) => {
    setLoading(true);
    const response = await invoiceFinderService.getInvoices(params);
    setInvoices(response.data);
    setLoading(false);
  };

  useEffect(() => {
    const extra = { page: currentPage }
    const filters = getFilters(form.getFieldsValue());
    fetchInvoices({ ...filters, ...extra });
  }, [refresh]);

  const loadMore = (e) => {
    const direction = e.target.value
    const _currentPage = direction == 'next' ? currentPage + 1 : currentPage - 1
    const extra = { page: _currentPage }
    const filters = getFilters(form.getFieldsValue());
    fetchInvoices({ ...filters, ...extra })
    setCurrentPage(_currentPage)
  }

  const getFilters = (values) => {
    const filters = {
      startDate: values.date && values.date[0].format('YYYY-MM-DD'),
      endDate: values.date && values.date[1].format('YYYY-MM-DD'),
      invoiceNumber: values.invoiceNumber,
      state: values.state
    }

    return Object.fromEntries(Object.entries(filters).filter(([_, v]) => v != null));
  }

  const onFinish = async (values) => {
    const params = getFilters(values);
    const extra = { page: currentPage }
    await fetchInvoices({ ...params, ...extra });
  };

  const checkInvoice = async (url) => {
    window.open(url, '_blank');
  };

  const showModal = (invoice) => {
    setInvoice(invoice);
    !isModalOpen ? setIsModalOpen(true) : setIsModalOpen(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onFinishDelete = async (values) => {
    try {
      setLoading(true);
      await invoiceFinderService.deleteInvoice({ invoiceId: invoice.id });
      message.success({
        content: 'Factura eliminada exitosamente.',
        style: {
          marginTop: '40vh'
        }
      });
      closeModal();
      setLoading(false);
      setInvoice(null);
      setRefresh(Math.random().toString(36));
    } catch (err) {
      console.error(err);
      message.error('Error interno.', err);
      setLoading(false);
    }
  };

  const columns = [
    {
      title: 'N° de factura',
      dataIndex: 'invoice_number',
      key: 'invoice_number'
    },
    {
      title: 'Monto',
      dataIndex: 'amount_total',
      key: 'amount_total',
      render: (text, record) => {
        return <p className="mb-0">
          {formatAmountByCurrency({
            countryCode: record.currency.locate_code,
            currencyCode: record.currency.code,
            amount: text
          })}
        </p>
      }
    },
    {
      title: 'Estado',
      dataIndex: 'state',
      key: 'state',
      render: (text, record) => {
        if (record.paid) {
          return 'Pagado'
        }

        return 'No Pagado'
      }
    },
    {
      title: '',
      dataIndex: '',
      key: 'check_invoice',
      width: 300,
      render: (text, record) => {
        return (
          <>
            <Button
              className="mr-20"
              type="primary"
              size="small"
              onClick={() => checkInvoice(record.url)}
            >
              Descargar factura
            </Button>
            <Button
              size="small"
              onClick={() => showModal(record)}
              disabled={record.agreement}
            >
              Eliminar
            </Button>
          </>
        )
      }
    },
  ];

  return (
    <Row justify="center">
      <Col lg={24} xs={24} className="mt-30">
        <Row justify="start">
          <Col lg={24} xs={24}>
            <h1>Buscador de facturas</h1>
          </Col>
          <Col lg={20} xs={24}>
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              style={{ width: '100%' }}
              size="large"
            >
              <Row justify="start" align="bottom" gutter={20}>
                <Col lg={5} xs={5}>
                  <Form.Item label="Fecha" name="date" className="mb-0">
                    <RangePicker
                      style={{ width: '100%' }}
                      locale={locale}
                      placeholder={['Desde', 'Hasta']}
                      picker="date" />
                  </Form.Item>
                </Col>
                <Col lg={5} xs={5}>
                  <Form.Item name="invoiceNumber" className="mb-0">
                    <Input placeholder="N° de factura" allowClear />
                  </Form.Item>
                </Col>
                <Col lg={5} xs={5}>
                  <Form.Item name="state" className="mb-0">
                    <Select placeholder="Estado" allowClear>
                      <Select.Option value="paid">
                        Pagado
                      </Select.Option>
                      <Select.Option value="unpaid">
                        No Pagado
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col lg={4} xs={4}>
                  <Button loading={loading} htmlType="submit" type="primary" block>Buscar</Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        <Row justify="center" className="mt-40">
          <Col lg={24}>
            <Spin spinning={loading}>
              <Table columns={columns} dataSource={invoices} pagination={false} bordered={false} />
              <Radio.Group value={'large'} onChange={loadMore} className="mb-20">
                <Radio.Button value="prev" disabled={currentPage == 1}>Anterior</Radio.Button>
                <Radio.Button value="next" disabled={!invoices || !invoices.length}>Siguiente</Radio.Button>
              </Radio.Group>
            </Spin>
          </Col>
        </Row>
        <Modal
          visible={isModalOpen}
          onCancel={closeModal}
          style={{ width: 700 }}
          footer={false}
          maskClosable={true}
          closable={false}
        >
          {
            invoice &&
            <Row justify="center" className="pt-10 pb-10 pl-10 pr-10">
              <Col lg={24}>
                <Form
                  form={form}
                  onFinish={onFinishDelete}
                  size="large"
                  layout="horizontal"
                >
                  <Row justify="center">
                    <Col lg={24}>
                      <h2>Está eliminando la factura n° {invoice.invoice_number}</h2>
                    </Col>
                  </Row>
                  <Row justify="center">
                    <Col lg={24} />
                  </Row>
                  <Row justify="center" className="mt-20">
                    <Col lg={9} className="mr-15">
                      <Button htmlType="submit" type="primary" block>Eliminar factura</Button>
                    </Col>
                    <Col lg={7}>
                      <Button type="primary" onClick={closeModal} block>Cancelar</Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          }
        </Modal>
      </Col>
    </Row>
  );
};

export default InvoiceFinder