import React from 'react';
import { Table, Row, Col } from 'antd';
import _ from 'lodash';
import { formatAmountByCurrency } from '../../helpers';

const SummaryCard = ({ summary, billing }) => {
  const columns = [
    {
      title: 'Prestacion',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Cantidad',
      dataIndex: 'count',
      key: 'count',
    },
    {
      title: () => (
        <div style={{ textAlign: 'right' }}>
          Precio
        </div>
      ),
      dataIndex: 'amount',
      key: 'amount',
      render: (text, record) => {
        return (
          <div
            style={{ textAlign: 'right' }}
            className="bold">
            {formatAmountByCurrency({
              countryCode: record.currency.locate_code,
              currencyCode: record.currency.code,
              amount: text
            })}
          </div>
        );
      }
    },
  ]


  const data = Object.keys(summary).map(key => {
    const currency = summary[key][0]['currency'];
    return {
      name: key,
      currency: currency,
      amount: _.sumBy(summary[key].map((item) => ({ amount: parseFloat(item.amount) })), 'amount'),
      count: summary[key].length
    }
  });

  return (
    <React.Fragment>
      <Row>
        <h2>Resumen por prestaciones</h2>
        <Col lg={24}>
          <Row>
            <Col lg={24}>
              <Table dataSource={data}
                columns={columns}
                pagination={false} />
            </Col>
          </Row>
          <Row>
            <Col lg={24}>
              <div className="text-bold text-right full-width pr-10">
                {
                  billing.total != billing.sub_total && <div style={{ fontSize: 11, color: 'grey' }}>
                    {formatAmountByCurrency({
                      countryCode: billing.currency.locate_code,
                      currencyCode: billing.currency.code,
                      amount: billing.sub_total
                    })}
                  </div>
                }
                Total {formatAmountByCurrency({
                  countryCode: billing.currency.locate_code,
                  currencyCode: billing.currency.code,
                  amount: billing.total
                })}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default SummaryCard