import moment from 'moment';
import * as _ from 'lodash';

export const formatDate = (date) => {
  return moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY');
};

export const formatAmount = (amount) => {
  return new Number(amount).toLocaleString('es-CL');
};

export const formatAmountByCurrency = (data) => {
  return new Intl.NumberFormat(data.countryCode, {
    style: 'currency',
    currency: data.currencyCode
  }).format(data.amount)
};

export const setTotalAmount = (record, total, reservations, setReservations) => {
  const items = reservations;
  const index = _.findIndex(reservations, { id: record.id })
  items[index].amount_total = total;
  setReservations([...items]);
}