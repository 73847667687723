import { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import { useParams, useLocation } from "react-router-dom";
import Detail from './components/steps/Detail';
import Documents from './components/steps/Documents';
import Info from './components/Info';

const InvoiceGeneratorPage = (props) => {
   let { type } = useParams();
   const [step, setStep] = useState();
   const [customComment, setCustomComment] = useState();
   const location = useLocation();
   const query = new URLSearchParams(location.search);

   useEffect(() => {
      setStep(props.step);
   });

   let params;

   if (type === 'agreement') {
      params = {
         type,
         companyId: query.get('companyId'),
         billingId: query.get('billingId'),
         countryId: query.get('countryId')
      };
   } else if (type === 'reservation') {
      params = {
         type,
         reservationId: query.get('reservationId'),
         companyId: query.get('companyId'),
         countryId: query.get('countryId')
      };
   } else {
      params = {
         type,
         reservationId: query.get('reservationId'),
         quantity: query.get('quantity'),
         specialComment: query.get('specialComment'),
         countryId: query.get('countryId')
      };
   }

   return (
      <Row className="invoice-generator pt-40 pb-60">
         <Col lg={24}>
            {
               step &&
               <Info {...params} />
            }
         </Col>
         <Col lg={24}>
            {step && step == 'detail' &&
               <Detail
                  {...params}
                  setStep={setStep}
                  setCustomComment={setCustomComment}
               />
            }
            {step && step == 'documents' &&
               <Documents
                  {...params}
                  customComment={customComment}
                  setStep={setStep}
               />
            }
         </Col>
      </Row>
   )
}

export default InvoiceGeneratorPage;
